import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Grid, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';

import LoadingComponent from '../../Ultils/LoadingComponent';
import { useAuthContext } from '../../../hooks/useAuthContext';

const BlogPosts = ({ setselected, setBlogToEdit }) => {
  const [BlogPostsData, setBlogPostsData] = React.useState([]);
  const [loading, setloading] = React.useState([]);
  const [alertfail, setalertfail] = React.useState(false);
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const fetchBlogPosts = async () => {
    setloading(true);
    const res = await axios.get('https://backend.interior-crowd.com/api/v1/blog/');
    setBlogPostsData(res.data.post);
    setloading(false);
  };
  const handleDelete = async (id) => {
    try {
      setloading(true);
      await axios.delete(
        `https://backend.interior-crowd.com/api/v1/blog/delete-blog/${id}`,
        {},
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setloading(false);
      fetchBlogPosts();
    } catch (error) {
      console.log(error);
    }
  };
  const handleUploadPic = async (e, entry) => {
    try {
      setloading(true);
      setalertfail(true);
      // setImages(e.target.files);
      const formData = new FormData();
      formData.append('blogID', entry._id);
      formData.append('titleImg', e.target.files[0]);
      const res = await axios.post(
        `https://backend.interior-crowd.com/api/v1/designer/update-blog-titleImg`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': user.token,
          },
        },
      );
      // console.log(...formData);
      setloading(false);
      setalertfail(false);
      alert('uploaded');
      navigate(`/Blog/${entry.title}/${entry._id}`);
    } catch (error) {
      setalertfail(true);
      console.log(error);
    }
  };
  React.useEffect(() => {
    fetchBlogPosts();
  }, []);
  return (
    <Grid item xs={12} sx={{ maxHeight: '70vh', overflowY: 'scroll' }}>
      <Typography variant="h5" component="span">
        {/* Total Projects: {loading ? <>loading</> : payoutsData[0].closed.length} */}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="right">description</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Update Title Pic</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <></>
            ) : (
              BlogPostsData.map((entry) => (
                <TableRow
                  key={entry._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {entry.title}
                  </TableCell>
                  <TableCell align="right">{entry.description}</TableCell>
                  <TableCell align="right">
                    <Switch />
                  </TableCell>
                  <TableCell align="right">
                    {loading ? (
                      <Button disabled variant="contained">
                        <CircularProgress color="success" />
                      </Button>
                    ) : (
                      <Button variant="contained" component="label">
                        Upload Title Image
                        <input
                          type="file"
                          hidden
                          onChange={(e) => {
                            handleUploadPic(e, entry);
                          }}
                        />
                      </Button>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => {
                        setBlogToEdit(entry);
                        setselected('Edit Blog');
                      }}
                      variant="contained"
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <DeleteIcon
                      onClick={() => handleDelete(entry._id)}
                      sx={{ cursor: 'pointer' }}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LoadingComponent />}
    </Grid>
  );
};

export default BlogPosts;
