import { Grid, Typography } from '@mui/material';
import React from 'react';

const LoadingComponent = () => (
  <Grid
    container
    justifyContent={'center'}
    sx={{
      width: '100%',
      color: 'black',
      mt: '2rem',
    }}
  >
    <Typography variant="h5">Loading</Typography>
  </Grid>
);

export default LoadingComponent;
