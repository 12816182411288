import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../Ultils/AdminNav';
import Footer from '../Ultils/Footer';

const HomeLayout = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '1920px ',
      // flexGrow: 1,
      // border: '10px solid green',
      overflowX: 'hidden',
    }}
  >
    <Navbar />
    <Outlet />
    <Footer />
  </div>
);

export default HomeLayout;
