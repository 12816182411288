import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Container,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Editor } from 'primereact/editor';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';

const ContestUpdate = () => {
  const { id } = useParams();
  const [Images, setImages] = useState([]);
  const [loading, setloading] = useState(true);
  const [alertfail, setalertfail] = useState(false);
  const [Contest, setContest] = useState([]);
  const [text, setText] = useState({
    description: '',
  });
  const [formInfo, setformInfo] = useState({
    deadline: '',
    budget: '',
    description: '',
  });
  const { user } = useAuthContext();
  const fetchContest = async () => {
    try {
      const res = await axios.get(
        `https://backend.interior-crowd.com/api/v1/contests/${id}`,
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setContest(res.data[0]);
      //   console.log(res);
      setformInfo({
        deadline: res.data[0].deadline,
        budget: res.data[0].budget,
        // description: `description: ${res.data[0].description}`,
        description: res.data[0].description,
      });
      setText({
        description: res.data[0].description,
      });
      //   console.log('hah', formInfo);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setformInfo({
      ...formInfo,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setalertfail(false);
    setloading(true);

    const formData = new FormData(e.currentTarget);
    formData.append('contestID', Contest._id);
    formData.append('description', text.description);
    for (let i = 0; i < Images.length; i++) {
      formData.append('images', Images[i]);
    }
    formData.set('deadline', formInfo.deadline);
    formData.set('budget', formInfo.budget);
    if (!formData.get('title')) {
      formData.set('title', Contest.title);
    }
    if (!formData.get('category')) {
      formData.set('category', Contest.category);
    }
    if (!formData.get('subcategory')) {
      formData.set('subcategory', Contest.subcategory);
    }
    if (!formData.get('location')) {
      formData.set('location', Contest.location);
    }
    if (!formData.get('deadline')) {
      formData.set('deadline', Contest.deadline);
    }
    // if (!formData.get('budget')) {
    //   formData.set('budget', Contest.budget);
    // }
    if (!formData.get('description')) {
      formData.set('description', Contest.description);
    }

    try {
      await axios.post(
        `https://backend.interior-crowd.com/api/v1/contest/update-contest`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      setText({});
      setloading(false);
      alert('updated');
      navigate(`/Single-Contest/${Contest._id}`);
      setloading(false);
    } catch (error) {
      setloading(false);
      setalertfail(true);
    }
  };

  useEffect(() => {
    fetchContest();
  }, []);

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          minHeight: '100vh',
        }}
      >
        <CircularProgress color="success" />
      </Grid>
    );
  }

  return (
    <Stack
      sx={{
        m: { xs: '5rem 0', sm: '5rem 0', md: '6rem 0' },
      }}
      direction="column"
      justifyContent="center"
      spacing={2}
    >
      <Typography textAlign="center" component="span" variant="h5">
        Update Contest
      </Typography>
      <Grid container width="100%" justifyContent="center" alignItems="center">
        <Paper
          elevation={3}
          sx={{
            maxWidth: '35rem',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            alignItems: 'center',
          }}
        >
          <form
            action=""
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid container gap={3} sx={{ padding: '0 1.5rem' }}>
              <TextField
                name="title"
                fullWidth
                id="standard-basic"
                label={`title: ${Contest.title}`}
                variant="standard"
              />
              <TextField
                name="category"
                fullWidth
                id="standard-basic"
                label={`Category: ${Contest.category}`}
                variant="standard"
              />
              <TextField
                name="subcategory"
                fullWidth
                id="standard-basic"
                label={`subcategory: ${Contest.subcategory}`}
                variant="standard"
              />
              <TextField
                name="location"
                fullWidth
                id="standard-basic"
                label={`location: ${Contest.location}`}
                variant="standard"
              />
              {/* <TextField
                name="description"
                fullWidth
                id="standard-basic"
                value={formInfo.description}
                label="Description"
                onChange={handleChange}
                variant="standard"
              /> */}
              <Container>
                <Editor
                  value={text.description}
                  required
                  onTextChange={(e) => {
                    setText({ ...text, description: e.htmlValue });
                  }}
                  style={{ height: '320px', width: '100%' }}
                />
              </Container>
              <Typography variant="body2">Deadline</Typography>
              <TextField
                name="deadline"
                fullWidth
                type="date"
                value={formInfo.deadline}
                onChange={handleChange}
                id="standard-basic"
                variant="standard"
              />
              {/* <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="standard-adornment-amount">
                  Budget
                </InputLabel>
                <Input
                  name="budget"
                  value={formInfo.budget}
                  onChange={handleChange}
                  id="standard-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl> */}
              {Images.length === 0 ? (
                <Button variant="contained" component="label">
                  Upload Images *
                  <input
                    type="file"
                    hidden
                    multiple
                    onChange={(e) => {
                      setImages(e.target.files);
                    }}
                  />
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => setImages([])}
                    variant="contained"
                    component="label"
                  >
                    discard selected
                  </Button>
                  <Button variant="contained" component="label">
                    +
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        setImages((prev) => [...prev, e.target.files[0]]);
                      }}
                    />
                  </Button>
                  <p>{Images.length} Images Selected.</p>
                </>
              )}
            </Grid>
            {loading ? (
              <Button
                type="submit"
                disabled
                variant="contained"
                sx={{
                  m: '2rem 0 0 0',
                }}
              >
                {loading ? (
                  <CircularProgress color="success" />
                ) : (
                  'Update Contest'
                )}
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{
                  m: '2rem 0 0 0',
                }}
              >
                {loading ? (
                  <CircularProgress color="success" />
                ) : (
                  'Update Contest'
                )}
              </Button>
            )}
            {!loading && alertfail ? (
              <Alert sx={{ mt: '1rem' }} severity="warning">
                Something went wrong.
              </Alert>
            ) : (
              <></>
            )}
          </form>
        </Paper>
      </Grid>
    </Stack>
  );
};

export default ContestUpdate;
