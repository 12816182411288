import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Card,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import axios from 'axios';

import LoadingComponent from '../Ultils/LoadingComponent';
import ParticlesComponent from '../Ultils/ParticlesComponent';

const Designer = () => {
  const [Designers, setDesigners] = useState([]);
  const [loading, setloading] = useState(true);
  const fetchDesigners = async () => {
    try {
      const res = await axios.get('https://backend.interior-crowd.com/api/v1/designer');

      setDesigners(res.data);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDesigners();
    console.log(Designers);
  }, []);

  // if (loading) {
  //   return <div style={{marginTop: '5rem', minHeight: '50vh', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}><h1>loading</h1></div>
  // }
  return (
    <Stack>
      <Box
        className="home-container"
        sx={{
          maxWidth: '1920px',
          width: '100vw',
          height: '30rem',
          display: 'flex',
          mt: '2rem',
          flexDirection: 'column',
          color: 'white',
        }}
      >
        <Container>
          <Typography
            maxWidth="15rem"
            sx={{
              backgroundColor: '#29384D',
              padding: '1rem',
              borderRadius: '10px',
              mt: '8rem',
              fontSize: { xs: '1.5rem', md: '1.5rem' },
            }}
            variant="h4"
          >
            OUR DESIGNERS
          </Typography>
          <Typography
            maxWidth="70%"
            sx={{
              mt: '2rem',
              backgroundColor: '#29384D',
              padding: '1rem',
              borderRadius: '10px',
              fontSize: { xs: '1rem', md: '1.5rem' },
            }}
            variant="h5"
          >
            The best design professionals in the world, ready to help you
            renovate your house, your office, your space.
          </Typography>
        </Container>
        <Grid xs={12} sx={{ display: 'flex', height: '20rem', zIndex: '-2' }}>
          <ParticlesComponent />
        </Grid>
      </Box>
      <Box sx={{ minHeight: '50vh', backgroundColor: '#333' }}>
        <Container maxWidth="lg" sx={{ mt: '2rem' }}>
          <Grid
            container
            item
            alignItems="center"
            flexDirection="column"
            xs={12}
            mt={4}
          >
            <Typography variant="h4" sx={{ m: '2rem 0' }} xs={12}>
              Designers
            </Typography>
            <Grid
              mt={4}
              container
              gap={2}
              item
              xs={12}
              justifyContent="space-between"
            >
              {loading ? (
                <LoadingComponent />
              ) : Designers.length === 0 ? (
                <>no Data</>
              ) : (
                Designers.map((designer) => (
                  <Grid key={designer._id} item xs={12} sm={5.7} md={3.7}>
                    <Link to={`/Single-Designer/${designer._id}`}>
                      <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              loading || !designer.coverPic[0]
                                ? 'https://d1s5wdh0uf8y8f.cloudfront.net/1400x420/designers/5c2de4b6b7172d00257543cb/f9WiaASyaaFgnbK5ulkA_open space-0004.jpg'
                                : `${designer.coverPic[0]}`
                            }
                            alt="green iguana"
                          />
                          <CardContent>
                            <Grid container item xs={12}>
                              <Grid item xs={8}>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                >
                                  {designer.name} {designer.surname}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {designer.profession} | {designer.location}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="D-Profile-pic-container">
                                  <img
                                    className="D-Profile-pic"
                                    src={
                                      loading || !designer.profilePic[0]
                                        ? 'https://d1s5wdh0uf8y8f.cloudfront.net/200x200/designers/5c2de4b6b7172d00257543cb/JoIxS028Q9qgx6hxYAI6_Giacomo Cattani foto.jpg'
                                        : `${designer.profilePic[0]}`
                                    }
                                    alt=""
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Link>
                  </Grid>
                ))
              )}
            </Grid>
            <Link to="/Designers">
              <Button variant="contained" sx={{ m: '2.5rem 0 4rem 0' }}>
                Browse More
              </Button>
            </Link>
          </Grid>
        </Container>
      </Box>
    </Stack>
  );
};

export default Designer;
