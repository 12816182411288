import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import LoadingComponent from '../Ultils/LoadingComponent';

const Closed = ({
  DesignerData,
  fetchDesigner,
  loading,
  setselected,
  setpaymentContest,
}) => {
  React.useEffect(() => {
    fetchDesigner();
  }, []);

  return (
    <Grid item xs={12} sx={{ maxHeight: '70vh', overflowY: 'scroll' }}>
      <Typography variant="h5" component="span">
        Total Projects: {loading ? <>loading</> : DesignerData[0].closed.length}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="right">Category</TableCell>
              <TableCell align="right">Budget</TableCell>
              <TableCell align="right">DeadLine</TableCell>
              <TableCell align="right">Proposal</TableCell>
              <TableCell align="right">Contest</TableCell>
              <TableCell align="right">Payment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <></>
            ) : DesignerData[0].closed.length === 0 ? (
              <></>
            ) : (
              DesignerData[0].closed.map((contest) => {
                if (
                  contest.payment === 'requested' ||
                  contest.payment === 'paid'
                )
                  return false;
                return (
                  <TableRow
                    key={contest._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {contest.title}
                    </TableCell>
                    <TableCell align="right">{contest.category}</TableCell>
                    <TableCell align="right">$ {contest.budget}</TableCell>
                    <TableCell align="right">{contest.deadline}</TableCell>
                    <TableCell align="right">
                      <Link to={`/Single-Project/${contest.acceptedproject}`}>
                        <Button variant="contained">View Proposal</Button>
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/Single-Contest/${contest._id}`}>
                        <Button variant="contained">View Contest</Button>
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          setpaymentContest(contest._id);
                          setselected('Withdraw Request');
                        }}
                        variant="contained"
                      >
                        Withdraw
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LoadingComponent />}
    </Grid>
  );
};

export default Closed;
