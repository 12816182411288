import { Button, Grid, Paper, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { Editor } from 'primereact/editor';
import axios from 'axios';
import React, { useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';

const AddBlog = () => {
  const [loading, setloading] = useState(false);
  const [alertfail, setalertfail] = useState(false);
  const [Images, setImages] = useState([]);
  const [text, setText] = useState({ titleImg: [] });
  const { user } = useAuthContext();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setalertfail(false);
    const formData = new FormData(e.currentTarget);
    formData.append('titleImg', text.titleImg);
    formData.append('content', text.content);
    try {
      setloading(true);
      const res = await axios.post(
        'https://backend.interior-crowd.com/api/v1/blog/post',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': user.token,
          },
        },
      );
      setText({ titleImg: [] });
      alert('posted successfully');
      setloading(false);
      // console.log(...formData);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Grid
      sx={{ mb: '5rem', mt: '2rem' }}
      container
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: '50rem',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <form
          action=""
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container gap={3} sx={{ padding: '0 1.5rem' }}>
            <TextField
              required
              name="title"
              fullWidth
              id="standard-basic"
              label="Title"
              variant="standard"
            />
            <TextField
              required
              name="description"
              fullWidth
              id="standard-basic"
              label="description"
              variant="standard"
            />
            {text.titleImg.length === 0 ? (
              <Button variant="contained" component="label">
                Upload title Image *
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    setText({ ...text, titleImg: e.target.files[0] });
                  }}
                />
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => setText({ ...text, titleImg: [] })}
                  variant="contained"
                  component="label"
                >
                  Discard Selected Image
                </Button>
                <p style={{ marginTop: '1rem' }}>
                  {text.titleImg.length} Image Selected
                </p>
              </>
            )}
            <Editor
              value={text.content}
              onTextChange={(e) => {
                setText({ ...text, content: e.htmlValue });
              }}
              style={{ height: '320px', width: '100%' }}
            />
          </Grid>
          {loading ? (
            <Button
              type="submit"
              disabled
              variant="contained"
              sx={{
                m: '2rem 0 0 0',
              }}
            >
              {loading ? <CircularProgress color="success" /> : 'Add Post'}
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              sx={{
                m: '2rem 0 0 0',
              }}
            >
              {loading ? <CircularProgress color="success" /> : 'Add Post'}
            </Button>
          )}
          {!loading && alertfail ? (
            <Alert sx={{ mt: '1rem' }} severity="warning">
              Something went wrong.
            </Alert>
          ) : (
            <></>
          )}
        </form>
      </Paper>
    </Grid>
  );
};

export default AddBlog;
