import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LoadingComponent from '../../Ultils/LoadingComponent';
import { useAuthContext } from '../../../hooks/useAuthContext';

const PaidHistory = () => {
  const [payoutsData, setpayoutsData] = React.useState([]);
  const [loading, setloading] = React.useState([]);
  const { user } = useAuthContext();
  const fetchPayouts = async () => {
    setloading(true);
    const res = await axios.get(
      'https://backend.interior-crowd.com/api/v1/payment/get-payouts/',
      {
        headers: {
          'x-auth-token': user.token,
        },
      },
    );
    setpayoutsData(res.data.payout);
    console.log(payoutsData);
    setloading(false);
  };
  React.useEffect(() => {
    fetchPayouts();
  }, []);
  return (
    <Grid item xs={12} sx={{ maxHeight: '70vh', overflowY: 'scroll' }}>
      <Typography variant="h5" component="span">
        {/* Total Projects: {loading ? <>loading</> : payoutsData[0].closed.length} */}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Designer</TableCell>
              <TableCell align="right">Contest</TableCell>
              <TableCell align="right">Information</TableCell>
              <TableCell align="right">Requested Date</TableCell>
              <TableCell align="right">Payment Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <></>
            ) : (
              payoutsData.map((entry) => {
                if (entry.contest.payment === 'paid') {
                  return (
                    <TableRow
                      key={entry._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {entry.name}
                      </TableCell>
                      <TableCell align="right">{entry.email}</TableCell>
                      <TableCell align="right">
                        <Link to={`/Single-Designer/${entry.designer._id}`}>
                          <Button variant="contained">View Designer</Button>
                        </Link>
                      </TableCell>
                      <TableCell align="right">
                        <Link to={`/Single-Contest/${entry.contest._id}`}>
                          <Button variant="contained">View Contest</Button>
                        </Link>
                      </TableCell>
                      <TableCell align="right">{entry.name}</TableCell>
                      <TableCell align="right">{entry.createdAt}</TableCell>
                      <TableCell align="right">
                        {entry.contest.payment}
                      </TableCell>
                    </TableRow>
                  );
                }
                return <></>;
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LoadingComponent />}
    </Grid>
  );
};

export default PaidHistory;
