import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Editor } from 'primereact/editor';

import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useAuthContext } from '../../hooks/useAuthContext';

const AddContest = ({ id, setselected }) => {
  const [Images, setImages] = useState([]);
  const [text, setText] = useState({});
  const [loading, setloading] = useState(false);
  const [alertfail, setalertfail] = useState(false);

  const { user } = useAuthContext();
  // console.log(Images[0].name);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setalertfail(false);
    setloading(true);
    const formData = new FormData(e.currentTarget);
    formData.append('description', text.description);
    for (let i = 0; i < Images.length; i++) {
      formData.append('file', Images[i]);
    }
    formData.append('client', id);
    try {
      const res = await axios.post(
        'https://backend.interior-crowd.com/api/v1/contests/create-contest',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': user.token,
          },
        },
      );
      const contestinfo = res.data;
      const payData = { purchaseContest: [res.data] };
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/clients/add-contest-to-client/${id}`,
        { contestID: `${contestinfo._id}` },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      const checkout = await axios.post(
        `https://backend.interior-crowd.com/api/v1/payment/client-payment/${id}`,
        payData,
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setText({});
      window.location.replace(checkout.data.url);
      setloading(false);
      // setselected('My Contests');
    } catch (error) {
      setloading(false);
      setalertfail(true);
    }
  };

  return (
    <Grid
      sx={{ mb: '5rem' }}
      container
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: '35rem',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <form
          action=""
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container gap={3} sx={{ padding: '0 1.5rem' }}>
            <TextField
              required
              name="title"
              fullWidth
              id="standard-basic"
              label="Title"
              variant="standard"
            />
            <TextField
              required
              name="category"
              fullWidth
              id="standard-basic"
              label="Category"
              variant="standard"
            />
            <TextField
              required
              name="subcategory"
              fullWidth
              id="standard-basic"
              label="Sub Category"
              variant="standard"
            />
            <TextField
              required
              name="location"
              fullWidth
              id="standard-basic"
              label="location"
              variant="standard"
            />
            {/* <TextField
              required
              name="description"
              fullWidth
              id="standard-basic"
              label="Description"
              variant="standard"
            /> */}
            <Editor
              value={text.description}
              required
              onTextChange={(e) => {
                setText({ ...text, description: e.htmlValue });
              }}
              style={{ height: '320px', width: '100%' }}
            />
            <Typography variant="body2">Deadline</Typography>
            <TextField
              name="deadline"
              fullWidth
              type="date"
              id="standard-basic"
              variant="standard"
            />
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">
                Budget
              </InputLabel>
              <Input
                required
                name="budget"
                id="standard-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
            {Images.length === 0 ? (
              <Button variant="contained" component="label">
                Upload Images *
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={(e) => {
                    setImages(e.target.files);
                  }}
                />
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => setImages([])}
                  variant="contained"
                  component="label"
                >
                  discard selected
                </Button>
                <Button variant="contained" component="label">
                  +
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      setImages((prev) => [...prev, e.target.files[0]]);
                    }}
                  />
                </Button>
                <p>{Images.length} Files Selected.</p>
              </>
            )}
          </Grid>
          {loading ? (
            <Button
              type="submit"
              disabled
              variant="contained"
              sx={{
                m: '2rem 0 0 0',
              }}
            >
              {loading ? <CircularProgress color="success" /> : 'Add Contest'}
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              sx={{
                m: '2rem 0 0 0',
              }}
            >
              {loading ? <CircularProgress color="success" /> : 'Add Contest'}
            </Button>
          )}
          {!loading && alertfail ? (
            <Alert sx={{ mt: '1rem' }} severity="warning">
              Something went wrong.
            </Alert>
          ) : (
            <></>
          )}
        </form>
      </Paper>
    </Grid>
  );
};

export default AddContest;
