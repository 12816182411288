import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
// import { useAuthContext } from './useAuthContext';

const ProtectedRoute = () => {
  // const { user } = useAuthContext();
  const user = localStorage.getItem('user');

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
