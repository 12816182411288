import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';
import LoadingComponent from '../Ultils/LoadingComponent';

const BrowseContests = ({ id }) => {
  const [contestData, setcontestData] = useState([]);
  const [loading, setloading] = useState(true);

  const fetchContests = async () => {
    try {
      const res = await axios.get('https://backend.interior-crowd.com/api/v1/contests');
      const { data } = res;
      setcontestData(data.contests);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchContests();
  }, []);

  return (
    <Grid item xs={12} sx={{ maxHeight: '70vh', overflowY: 'scroll' }}>
      {/* <Typography variant='h5' component={'span'}>Total Contests: {loading ? <>loading</> : contestData.length}</Typography> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Category</TableCell>
              <TableCell align="right">Budget</TableCell>
              <TableCell align="right">DeadLine</TableCell>
              <TableCell align="right">Bid</TableCell>
              <TableCell align="right">Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <></>
            ) : (
              contestData.map((contest) => {
                if (
                  contest.status === 'close' ||
                  contest.status === 'unpublished'
                ) {
                  return false;
                }
                return (
                  <TableRow
                    key={contest._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {contest.title}
                    </TableCell>
                    <TableCell align="right">{contest.status}</TableCell>
                    <TableCell align="right">{contest.category}</TableCell>
                    <TableCell align="right">$ {contest.budget}</TableCell>
                    <TableCell align="right">{contest.deadline}</TableCell>
                    <TableCell align="right">
                      <Link
                        to={`/Add-Project/${id}/${contest.title}/${contest.client}/${contest._id}`}
                      >
                        <Button variant="contained">Apply</Button>
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/single-contest/${contest._id}`}>
                        <Button variant="contained">Details</Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LoadingComponent />}
    </Grid>
  );
};

export default BrowseContests;
