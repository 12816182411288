import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { Editor } from 'primereact/editor';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useAuthContext } from '../../hooks/useAuthContext';

const AddProject = ({ id }) => {
  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
        box-sizing: border-box;
        width: 100%;
        min-height: 10rem;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 12px;
        border-radius: 12px 12px 0 12px;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${
          theme.palette.mode === 'dark' ? grey[700] : grey[200]
        };
        box-shadow: 0px 2px 2px ${
          theme.palette.mode === 'dark' ? grey[900] : grey[50]
        };
    
        &:hover {
          border-color: ${blue[400]};
        }
    
        &:focus {
          outline: 0;
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${
            theme.palette.mode === 'dark' ? blue[600] : blue[200]
          };
        }
    
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `,
  );

  const { designerid, contestid, title, clientid } = useParams();

  const { user } = useAuthContext();

  const [text, setText] = useState({});
  const [Images, setImages] = useState([]);
  const [Files, setFiles] = useState([]);
  const [loading, setloading] = useState(false);
  const [alertfail, setalertfail] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setalertfail(false);
    setloading(true);
    const formData = new FormData(e.currentTarget);
    formData.append('designer', designerid);
    formData.append('contest', contestid);
    formData.append('description', text.description);
    for (let i = 0; i < Images.length; i++) {
      formData.append('images', Images[i]);
    }
    for (let i = 0; i < Files.length; i++) {
      formData.append('files', Files[i]);
    }
    try {
      // create project
      const res = await axios.post(
        'https://backend.interior-crowd.com/api/v1/projects/create-project',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': user.token,
          },
        },
      );
      const proposalinfo = res.data;

      // add project to contest
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/contests/${contestid}`,
        { projectID: proposalinfo._id },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );

      // add contest to applied
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/designer/${designerid}`,
        { contestID: contestid },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      // post notification to client
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/clients/add-notification/${clientid}`,
        {
          content: {
            contest: contestid,
            text: `${title} recieved a new project`,
          },
        },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setText({});
      setloading(false);
      navigate(`/Single-Project/${proposalinfo._id}`);
    } catch (error) {
      console.log(error);
      setloading(false);
      setalertfail(true);
    }
  };

  return (
    <Grid
      container
      width="100%"
      maxWidth={'1920px'}
      justifyContent="center"
      alignItems="center"
      sx={{ m: '8rem 0' }}
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: '35rem',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4">Add Project</Typography>
        <form
          action=""
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Grid container gap={3} sx={{ padding: '0 1.5rem' }}>
            <TextField
              required
              name="title"
              fullWidth
              id="standard-basic"
              label="Title"
              variant="standard"
            />
            <Grid item xs={12}>
              {Images.length === 0 ? (
                <Button variant="contained" component="label">
                  Upload Images *
                  <input
                    type="file"
                    hidden
                    multiple
                    onChange={(e) => {
                      setImages(e.target.files);
                    }}
                  />
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => setImages([])}
                    variant="contained"
                    component="label"
                  >
                    Discard Selected Images
                  </Button>
                  <Button
                    sx={{ ml: '1rem' }}
                    variant="contained"
                    component="label"
                  >
                    +
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        setImages((prev) => [...prev, e.target.files[0]]);
                      }}
                    />
                  </Button>
                  <p style={{ marginTop: '1rem' }}>
                    {Images.length} Images Selected.
                  </p>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {Files.length === 0 ? (
                <Button variant="contained" component="label">
                  Upload Files *
                  <input
                    type="file"
                    hidden
                    multiple
                    onChange={(e) => {
                      setFiles(e.target.files);
                    }}
                  />
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => setFiles([])}
                    variant="contained"
                    component="label"
                  >
                    Discard Selected Files
                  </Button>
                  <Button
                    sx={{ ml: '1rem' }}
                    variant="contained"
                    component="label"
                  >
                    +
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        setFiles((prev) => [...prev, e.target.files[0]]);
                      }}
                    />
                  </Button>
                  <p style={{ marginTop: '1rem' }}>
                    {Files.length} Files Selected.
                  </p>
                </>
              )}
            </Grid>
            <Grid container item xs={12}>
              {/* <Textarea
                required
                name="description"
                aria-label="empty textarea"
                placeholder="Description"
              /> */}
              <Typography variant="body2">Description (required)</Typography>
              <Editor
                value={text.description}
                required
                onTextChange={(e) => {
                  setText({ ...text, description: e.htmlValue });
                }}
                style={{ height: '320px', width: '100%' }}
              />
            </Grid>
          </Grid>
          {loading ? (
            <Button
              type="submit"
              disabled
              variant="contained"
              sx={{
                m: '2rem 0 0 0',
              }}
            >
              {loading ? <CircularProgress color="success" /> : 'Add Project'}
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              sx={{
                m: '2rem 0 0 0',
              }}
            >
              {loading ? <CircularProgress color="success" /> : 'Add Project'}
            </Button>
          )}
          {!loading && alertfail ? (
            <Alert sx={{ mt: '1rem' }} severity="warning">
              Something went wrong.
            </Alert>
          ) : (
            <></>
          )}
        </form>
      </Paper>
    </Grid>
  );
};

export default AddProject;
