import { Button, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useAuthContext } from '../../../hooks/useAuthContext';

const ShowEducation = ({
  settoggleAddEdu,
  loading,
  DesignerData,
  Deleteloading,
  setDeleteloading,
  setShowEdu,
  settoggleEditEdu,
  seteduToEdit,
}) => {
  const { user } = useAuthContext();
  const handleDelete = async (id) => {
    try {
      setDeleteloading(true);
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/designer/delete-education/${DesignerData[0]._id}`,
        { eduID: id },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setDeleteloading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Button
        onClick={() => {
          settoggleAddEdu(true);
          setShowEdu(false);
        }}
        sx={{
          width: '10rem',
        }}
        variant="contained"
      >
        Add Education
      </Button>
      <Grid container justifyContent="space-between">
        {loading ? (
          <>loading</>
        ) : DesignerData[0].education.length === 0 ? (
          <></>
        ) : (
          DesignerData[0].education.map((entry, idx) => (
            <Grid
              key={entry._id}
              container
              item
              xs={12}
              sm={5.8}
              md={3.8}
              flexDirection="column"
            >
              <Paper
                sx={{
                  padding: '1.5rem',
                  backgroundColor: '#333',
                  color: 'white',
                  borderRadius: '10px',
                  minHeight: '7rem !important',
                  height: '100%',
                  mt: '1rem',
                }}
                elevation={3}
              >
                <Grid container justifyContent="space-between">
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {entry.startdate} |{' '}
                      {entry.enddate === '' ? 'current' : entry.enddate}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="flex-end" gap={2} item xs={4}>
                    <EditIcon
                      onClick={() => {
                        seteduToEdit({ ...entry, index: idx });
                        setShowEdu(false);
                        settoggleEditEdu(true);
                      }}
                      sx={{ cursor: 'pointer' }}
                    />
                    {Deleteloading ? (
                      <CircularProgress color="success" />
                    ) : (
                      <DeleteIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleDelete(entry._id)}
                      />
                    )}
                  </Grid>
                </Grid>
                <Typography variant="body1">{entry.uniname}</Typography>
                <Typography variant="body1">{entry.profession}</Typography>
                <Typography variant="body1">{entry.degree}</Typography>
              </Paper>
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
};

export default ShowEducation;
