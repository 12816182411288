import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import HomeIcon from '@mui/icons-material/HouseOutlined';
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined';
import { Link } from 'react-router-dom';

const RoleLogin = () => (
  <Box
    className="login-container"
    sx={{
      height: '100%',
      maxWidth: '1920px',
      width: '100vw',
      mt: '2rem',
      padding: { md: '2.5rem 4rem' },
    }}
  >
    <Container>
      <Grid
        item
        xs={12}
        container
        width={'100%'}
        minHeight={'80vh'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Paper
          elevation={3}
          sx={{
            width: '100%',
            padding: { xs: '3rem 1rem', md: '3rem 5rem' },
            marginTop: { xs: '5rem', md: '0' },
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{ fontSize: { xs: '1.2rem', md: '2rem' } }}
            variant="h4"
          >
            What are you looking for?
          </Typography>
          <Grid container xs={12} mt={2}>
            <Grid
              container
              xs={12}
              md={5.7}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={2}
            >
              <HomeIcon sx={{ fontSize: '50px' }} />
              <Typography variant="body2">ARE YOU A CUSTOMER?</Typography>
              <Typography variant="body2">
                Get dozens of high quality design Projects.
              </Typography>
              <Link to="/Login">
                <Button
                  variant="contained"
                  sx={{
                    m: '0 0 1.5rem 0',
                  }}
                >
                  Client Login
                </Button>
              </Link>
            </Grid>
            <Grid
              container
              xs={12}
              md={5.7}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={2}
            >
              <AllInclusiveOutlinedIcon sx={{ fontSize: '50px' }} />
              <Typography variant="body2">ARE YOU A DESIGNER?</Typography>
              <Typography variant="body2">
                Hundreds of clients fro mall around the world.
              </Typography>
              <Link to="/Designer-Login">
                <Button
                  variant="contained"
                  sx={{
                    m: '0 0 1.5rem 0',
                  }}
                >
                  Designer Login
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Container>
  </Box>
);

export default RoleLogin;
