import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import logo from '../../assets/footer/logo_transparent.png';
import ParticlesComponent from '../Ultils/ParticlesComponent';

const AboutUs = () => (
  <Stack>
    <Box
      className="home-container"
      sx={{
        maxWidth: '1920px',
        width: '100vw',
        height: '30rem',
        display: 'flex',
        mt: '2rem',
        flexDirection: 'column',
        color: 'white',
      }}
    >
      <Container>
        <Typography
          mb="2rem"
          maxWidth="15rem"
          sx={{
            backgroundColor: '#29384D',
            padding: '1rem',
            borderRadius: '10px',
            mt: '10rem',
            fontSize: { xs: '1.5rem', md: '1.5rem' },
          }}
          variant="h4"
        >
          About US
        </Typography>
        <Typography
          sx={{
            maxWidth: { xs: '100%', md: '70%' },
            backgroundColor: '#29384D',
            padding: '1rem',
            borderRadius: '10px',
            fontSize: { xs: '1.25rem', md: '1.25rem' },
          }}
          variant="h5"
        >
          Welcome to Interior Crowd - Where Creativity Meets Opportunity!
        </Typography>
      </Container>
      <Grid xs={12} sx={{ display: 'flex', height: '20rem', zIndex: '-2' }}>
        <ParticlesComponent />
      </Grid>
    </Box>
    <Box sx={{ backgroundColor: '#333', pb: '4rem' }}>
      <Container maxWidth="lg">
        <Grid container mt="4rem" gap="1rem">
          <Grid container>
            <Grid
              item
              xs={12}
              container
              flexDirection="column"
              gap="1rem"
              sm={6}
            >
              <Typography component="span" variant="h4">
                We | Our Story:
              </Typography>
              <Typography mt="1rem" component="span" variant="body1">
                At Interior Crowd, we believe in the power of collaboration and
                the endless possibilities of creativity. Our platform serves as
                a vibrant marketplace where clients and designers converge to
                bring ideas to life and create something extraordinary together.
              </Typography>
              <Typography component="span" variant="body1">
                Interior Crowd was born out of a passion for connecting talented
                designers with clients seeking innovative solutions. We noticed
                a gap in the market where creative minds were yearning for
                opportunities to showcase their skills, while clients were
                searching for the perfect design match for their projects. Thus,
                we set out on a mission to bridge this gap and empower both
                clients and designers to thrive.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sm={6}
              sx={{ mt: { xs: '2rem', sm: '0' } }}
            >
              <img
                style={{ backgroundColor: '#48cfad', borderRadius: '10px' }}
                width="250px"
                height="250px"
                src={logo}
                alt="no img"
              />
            </Grid>
          </Grid>
          <Typography
            textAlign="center"
            mt="3rem"
            component="span"
            variant="h4"
          >
            What We Do!
          </Typography>
          <Typography component="span" variant="body1">
            At Interior Crowd, we facilitate a seamless process for clients to
            submit their projects and for designers to submit their proposals.
            Whether you&apos;re a client with a vision in mind or a designer
            bursting with creativity, our platform provides the perfect avenue
            for you to connect and collaborate. Through our unique competition
            format, clients have the opportunity to review multiple proposals
            and select the design that resonates with them the most.
          </Typography>
          <Typography
            textAlign="center"
            mt="3rem"
            component="span"
            variant="h4"
          >
            Join Us Today:
          </Typography>
          <Typography mb="4rem" component="span" variant="body1">
            Ready to embark on your creative journey? Whether you&apos;re a
            client ready to kickstart your project or a designer eager to
            showcase your talents, Interior Crowd is here to support you every
            step of the way. Join our community today and experience the magic
            of collaboration like never before.
          </Typography>
        </Grid>
      </Container>
    </Box>
  </Stack>
);

export default AboutUs;
