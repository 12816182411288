import React, { useState, useEffect } from 'react';
import {
  Box,
  CssBaseline,
  Button,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';

const Downloadmails = () => {
  const [clientEmails, setClientEmails] = useState([]);
  const [designerEmails, setDesignerEmails] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchClientEmails = async () => {
    try {
      const response = await axios.get(
        'https://backend.interior-crowd.com/api/v1/clients/getEmail',
      );
      setClientEmails(response.data.emails);
    } catch (error) {
      console.error('Error fetching client emails:', error);
    }
  };

  const fetchDesignerEmails = async () => {
    try {
      const response = await axios.get(
        'https://backend.interior-crowd.com/api/v1/designer/designerEmail',
      );
      setDesignerEmails(response.data.emails);
    } catch (error) {
      console.error('Error fetching designer emails:', error);
    }
  };

  const handleRefresh = () => {
    setLoading(true);
    fetchClientEmails();
    fetchDesignerEmails();
    setLoading(false);
  };

  useEffect(() => {
    handleRefresh();
  }, []); // Call handleRefresh on component mount

  return (
    <>
      <Box textAlign="center" mt={2} mb={2}>
        <Button variant="contained" onClick={handleRefresh}>
          Refresh
        </Button>
      </Box>
      <CssBaseline />
      <div
        style={{
          maxWidth: '100%',
          margin: 'auto',
          backgroundColor: '#f5f5f5',
          padding: '20px',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={12}
            sm={6}
            textAlign="center"
            style={{
              overflowY: 'scroll',
              maxHeight: '300px',
              direction: 'rtl',
              scrollbarColor: 'black',
              paddingRight: '10px',
            }}
          >
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                }}
              >
                Client Emails
              </Typography>
              {loading ? (
                <Typography color="black">Loading client emails...</Typography>
              ) : (
                <Stack spacing={1} direction="column">
                  {clientEmails.map((email, index) => (
                    <Box key={email} bgcolor="#f0f0f0" p={2} borderRadius={4}>
                      <Typography color="black">{email}</Typography>
                    </Box>
                  ))}
                </Stack>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            textAlign="center"
            style={{
              overflowY: 'scroll',
              maxHeight: '300px',
              scrollbarColor: 'black',
              paddingLeft: '10px',
            }}
          >
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                }}
              >
                Designer Emails
              </Typography>
              {loading ? (
                <Typography color="black">
                  Loading designer emails...
                </Typography>
              ) : (
                <Stack spacing={1} direction="column">
                  {designerEmails.map((email, index) => (
                    <Box key={email} bgcolor="#f0f0f0" p={2} borderRadius={4}>
                      <Typography color="black">{email}</Typography>
                    </Box>
                  ))}
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Downloadmails;
