import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';

const LatestPostCard = ({ post, loading }) => {
  const slug = post.title.replace(/\s+/g, '-').toLowerCase();
  return (
    <Grid item xs={12} sm={5.7} md={3.7}>
      <Link to={`/Blog/${slug}/${post._id}`}>
        <Card sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={loading ? '' : post.titleImg}
              alt="green iguana"
            />
            <CardContent>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" component="div">
                    {loading ? <></> : post.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {loading ? <></> : post.description}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Grid>
  );
};

export default LatestPostCard;
