import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuthContext } from './useAuthContext';

const AdminProtected = () => {
  const { user } = useAuthContext();

  return user && user.role === 'admin' ? (
    <Outlet />
  ) : (
    <Navigate to="/Admin/Admin-login" />
  );
};

export default AdminProtected;
