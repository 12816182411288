import React, { useEffect } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LoadingComponent from '../Ultils/LoadingComponent';
import { useAuthContext } from '../../hooks/useAuthContext';

const ClientNotifications = ({
  ClientData,
  loading,
  fetchClient,
  setloading,
}) => {
  const { user } = useAuthContext();
  const handleDelete = async (id) => {
    try {
      setloading(true);
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/clients/delete-notification/${ClientData[0]._id}`,
        { notificationID: id },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setloading(false);
      fetchClient();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchClient();
  }, []);
  return (
    <Grid item xs={12} sx={{ width: '100%' }} container>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Notifications</TableCell>
              <TableCell align="right">View Contest</TableCell>
              <TableCell align="right">Trash</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <></>
            ) : ClientData[0].notifications.length === 0 ? (
              <></>
            ) : (
              ClientData[0].notifications.toReversed().map((entry) => (
                <TableRow
                  key={entry._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {entry.content.text}
                  </TableCell>
                  <TableCell align="right">
                    <Link to={`/single-contest/${entry.content.contest}`}>
                      <Button variant="contained">Contest</Button>
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    <DeleteIcon
                      onClick={() => handleDelete(entry._id)}
                      sx={{ cursor: 'pointer' }}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LoadingComponent />}
    </Grid>
  );
};

export default ClientNotifications;
