import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useAuthContext } from '../../../hooks/useAuthContext';

const AddWork = ({ id, settoggleAddWork, setShowWork }) => {
  const [loading, setloading] = useState(false);
  const [alertfail, setalertfail] = useState(false);
  const empty = {
    startdate: '',
    enddate: '',
    name: '',
    location: '',
    link: 'https://www.example.com',
  };
  const [AddWorkData, setAddWorkData] = useState(empty);
  const { user } = useAuthContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setalertfail(false);
    setloading(true);
    // const formData = new FormData(e.currentTarget);
    try {
      if (AddWorkData === empty) {
        alert('pls add data');
        setloading(false);
        return;
      }
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/designer/add-work/${id}`,
        AddWorkData,
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setloading(false);
      setAddWorkData(empty);
      alert('Experience Successfully Added');
      settoggleAddWork(false);
      setShowWork(true);
    } catch (error) {
      setloading(false);
      setalertfail(true);
    }
  };
  const handleChange = (e) => {
    setAddWorkData({
      ...AddWorkData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Grid container width="100%" justifyContent="center" alignItems="center">
      <Paper
        elevation={3}
        sx={{
          maxWidth: '35rem',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <form
          action=""
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container gap={3} sx={{ padding: '0 1.5rem' }}>
            <Typography component="span" variant="body2">
              Start Date
            </Typography>
            <TextField
              required
              value={AddWorkData.startdate}
              onChange={handleChange}
              name="startdate"
              fullWidth
              type="date"
              id="standard-basic"
              variant="standard"
            />
            <Typography component="span" variant="body2">
              End Date
            </Typography>
            <TextField
              value={AddWorkData.enddate}
              onChange={handleChange}
              name="enddate"
              fullWidth
              type="date"
              id="standard-basic"
              variant="standard"
            />
            <TextField
              required
              value={AddWorkData.name}
              onChange={handleChange}
              name="name"
              fullWidth
              id="standard-basic"
              label="Company"
              variant="standard"
            />
            <TextField
              required
              value={AddWorkData.location}
              onChange={handleChange}
              name="location"
              fullWidth
              id="standard-basic"
              label="location"
              variant="standard"
            />
            <TextField
              value={AddWorkData.link}
              onChange={handleChange}
              name="link"
              fullWidth
              type="url"
              id="standard-basic"
              label="Link"
              variant="standard"
            />
          </Grid>
          <Grid container justifyContent="center" gap="1rem">
            <Grid container justifyContent="center" gap={2}>
              {loading ? (
                <Button
                  type="submit"
                  disabled
                  variant="contained"
                  sx={{
                    m: '2rem 0 0 0',
                  }}
                >
                  {loading ? (
                    <CircularProgress color="success" />
                  ) : (
                    'Add New Work Experience'
                  )}
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    m: '2rem 0 0 0',
                  }}
                >
                  {loading ? (
                    <CircularProgress color="success" />
                  ) : (
                    'Add New Work Experience'
                  )}
                </Button>
              )}
              <Button
                onClick={() => {
                  settoggleAddWork(false);
                  setShowWork(true);
                }}
                sx={{
                  m: '2rem 0 0 0',
                }}
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
            {!loading && alertfail ? (
              <Alert sx={{ mt: '1rem' }} severity="warning">
                Something went wrong.
              </Alert>
            ) : (
              <></>
            )}
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default AddWork;
