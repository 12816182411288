/* eslint-disable */
import React from 'react';
import './newHome.css';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const NewFooter = () => {
  let a;
  return (
    <>
      <footer id="footer" className="footer">
        <div className="container footer-top">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 footer-about">
              <a href="index.html" className="d-flex align-items-center">
                <span className="sitename">Interior Crowd</span>
              </a>
              <div className="footer-contact pt-3">
                <p>128, 128 City Road</p>
                <p>LONDON, EC1V 2NX ,UNITED KINGDOM</p>
                <p className="mt-3">
                  <strong>Phone:</strong> <span>+44 (0)203 621 0640</span>
                </p>
                <p>
                  <strong>Email:</strong>{' '}
                  <span>contact@interior-crowd.com</span>
                </p>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right" />{' '}
                  <Link to="/">
                    <Typography variant="body1" color="#444444">
                      Home
                    </Typography>
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{' '}
                  <Link to="/About-us">
                    <Typography variant="body1" color="#444444">
                      About Us
                    </Typography>
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{' '}
                  <Link to="/Blog">
                    <Typography variant="body1" color="#444444">
                      Blog
                    </Typography>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right" />{' '}
                  <Link to="/Contests">
                    <Typography variant="body1" color="#444444">
                      Contests
                    </Typography>
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right" />{' '}
                  <Link to="/Designers">
                    <Typography variant="body1" color="#444444">
                      Designers
                    </Typography>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-12">
              <h4>Follow Us</h4>
              <p>
                "Stay connected and be the first to know about our latest
                updates and exclusive offers by following us on social media!"
              </p>
              <div className="social-links d-flex">
                <a href="">
                  <i className="bi bi-twitter-x" />
                </a>
                <a href="">
                  <i className="bi bi-facebook" />
                </a>
                <a href="">
                  <i className="bi bi-instagram" />
                </a>
                <a href="">
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container copyright text-center mt-4">
          <p>
            © <span>Copyright</span>{' '}
            <strong className="px-1 sitename">Interior-Crowd</strong>{' '}
            <span>All Rights Reserved</span>
          </p>
          <div className="credits">
            Designed by{' '}
            <a style={{ color: 'black' }} href="https://www.excyan.com/">
              Excyan | Software and IT Engineering Solutions
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default NewFooter;
