import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import logo from '../../assets/footer/logo_transparent.png';

const Footer = () => (
  <Grid
    container
    sx={{
      maxWidth: '1920px',
      width: '100%',
      backgroundColor: '#29384D',
      maxHeight: { md: '20rem' },
      overflow: 'hidden',
      padding: '1rem',
    }}
  >
    <Grid
      item
      container
      flexDirection="column"
      alignItems="center"
      xs={12}
      sm={6}
      md={4}
      sx={{
        minHeight: '100%',
        justifyContent: { xs: 'center', md: 'flex-start' },
        padding: '1rem',
      }}
    >
      <img src={logo} alt="no img" className="footer-logo" />
    </Grid>
    <Grid
      item
      container
      gap="1rem"
      flexDirection="column"
      alignItems="flex-start"
      xs={12}
      sm={6}
      md={4}
      sx={{
        minHeight: '100%',
        padding: { md: '1rem 5rem', xs: '1rem' },
      }}
    >
      <Typography variant="h5" color="white">
        Navigation
      </Typography>
      <div className="footer-btn">
        <Link to="/">
          <Typography variant="body1" color="white">
            Home
          </Typography>
        </Link>
      </div>
      <div className="footer-btn">
        <Link to="/About-Us">
          <Typography variant="body1" color="white">
            About Us
          </Typography>
        </Link>
      </div>
      <div className="footer-btn">
        <Link to="/Contests">
          <Typography variant="body1" color="white">
            Contests
          </Typography>
        </Link>
      </div>
      <div className="footer-btn">
        <Link to="/Designers">
          <Typography variant="body1" color="white">
            Designers
          </Typography>
        </Link>
      </div>
      <div className="footer-btn">
        <Link to="/Blog">
          <Typography variant="body1" color="white">
            Blog
          </Typography>
        </Link>
      </div>
    </Grid>
    <Grid
      item
      container
      gap="1rem"
      flexDirection="column"
      alignItems="flex-start"
      xs={12}
      md={4}
      sx={{
        minHeight: '100%',
        padding: { md: '1rem 5rem', xs: '1rem' },
      }}
    >
      <Typography variant="h5" color="white">
        Connect With Us
      </Typography>
      <div className="footer-btn">
        <a href="mailto:contact@interior-crowd.com">
          <Typography variant="body1" color="white">
            Support
          </Typography>
        </a>
      </div>
      <Typography variant="body1" color="white">
        Company Address :
        <br />
        128, 128 City Road ,LONDON, EC1V 2NX ,UNITED KINGDOM
      </Typography>
      <Typography variant="body1" color="white">
        Phone number : +44 (0)203 621 0640
      </Typography>
      {/* <div className="footer-btn">
        <Link to="/">
          <InstagramIcon />
        </Link>
        <Link to="/" style={{ paddingLeft: '1rem' }}>
          <FacebookIcon />
        </Link>
      </div> */}
    </Grid>
  </Grid>
);

export default Footer;
