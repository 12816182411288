import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Badge, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import BrowseContests from './BrowseContests';
import Applied from './Applied';
import Closed from './Closed';
import Settings from './Settings';
import Notifications from './Notifications';
import WithdrawForm from '../payment/WithdrawForm';
import DesignerPending from './DesignerPending';
import { useAuthContext } from '../../hooks/useAuthContext';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DesignerDashboard = () => {
  const [selected, setselected] = useState('Browse Contests');
  const [DesignerData, setDesignerData] = useState([]);
  const [loading, setloading] = useState(true);
  const [paymentContest, setpaymentContest] = useState([]);

  const theme = useTheme();

  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatch } = useAuthContext();

  // eslint-disable-next-line consistent-return
  const fetchDesigner = async () => {
    try {
      setloading(true);
      const res = await axios.get(
        `https://backend.interior-crowd.com/api/v1/designer/${id}`,
      );
      const { data } = res;
      setDesignerData(data);
      const user = await JSON.parse(localStorage.getItem('user'));
      if (!data[0] || user.user !== data[0]._id) {
        localStorage.removeItem('user');
        dispatch({ type: 'LOGOUT' });
        return navigate(`/Designer-Login`);
      }
      setloading(false);
    } catch (error) {
      // navigate(`/login`);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDesigner();

    // eslint-disable-next-line
  }, []);

  return (
    <Stack>
      <Box
        className="home-container"
        sx={{
          maxWidth: '1920px',
          width: '100vw',
          minHeight: '30rem',
          display: 'flex',
          // pt: '2rem',
          flexDirection: 'column',
          color: 'white',
        }}
      >
        <Grid
          container
          sx={{
            minHeight: '90vh',
            maxWidth: '100%',
            // border: '1px solid yellow',
          }}
        >
          <CssBaseline />
          <Grid
            container
            item
            sx={{
              // border: '1px solid red',
              flexShrink: 0,
              p: { xs: '4rem 1rem 0 1rem', md: '6rem 1rem' },
              flexDirection: { xs: 'row', md: 'column' },
              backgroundColor: '#333',
            }}
            xs={12}
            md={2}
          >
            <Grid
              item
              onClick={() => setselected('Notifications')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Notifications</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Browse Contests')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Browse Contests</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Applied')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Applied</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Closed')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Closed</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Pending')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Pending Payment</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Settings')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Settings</p>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={10}
            container
            sx={{
              // flexGrow: 1,
              bgcolor: 'background.default',
              // border: '1px solid black',
              flexWrap: 'wrap',
              flexDirection: 'column',
              padding: '6rem 2rem 0 2rem',
            }}
          >
            <Typography textAlign="center" variant="h4" color={'black'}>
              {selected}
              {!loading && DesignerData[0].notifications.length === 0 ? (
                <></>
              ) : (
                <Badge
                  onClick={() => setselected('Notifications')}
                  badgeContent={
                    loading ? <></> : DesignerData[0].notifications.length
                  }
                  color="primary"
                  sx={{ cursor: 'pointer', ml: '2rem' }}
                >
                  <NotificationsIcon />
                </Badge>
              )}
            </Typography>
            <Box
              sx={{
                maxWidth: {
                  xs: '80vw',
                  sm: '100%',
                  padding: '0 1.5rem',
                  overflow: 'hidden',
                },
              }}
            >
              {selected === 'Browse Contests' && <BrowseContests id={id} />}
              {selected === 'Withdraw Request' && (
                <WithdrawForm
                  DesignerData={DesignerData}
                  setselected={setselected}
                  paymentContest={paymentContest}
                  id={id}
                />
              )}
              {selected === 'Applied' && <Applied id={id} />}
              {selected === 'Closed' && (
                <Closed
                  loading={loading}
                  fetchDesigner={fetchDesigner}
                  DesignerData={DesignerData}
                  setselected={setselected}
                  setpaymentContest={setpaymentContest}
                />
              )}
              {selected === 'Pending' && (
                <DesignerPending
                  loading={loading}
                  fetchDesigner={fetchDesigner}
                  DesignerData={DesignerData}
                  setselected={setselected}
                  setpaymentContest={setpaymentContest}
                />
              )}
              {selected === 'Settings' && (
                <Settings
                  loading={loading}
                  setloading={setloading}
                  fetchDesigner={fetchDesigner}
                  DesignerData={DesignerData}
                />
              )}
              {selected === 'Notifications' && (
                <Notifications
                  loading={loading}
                  fetchDesigner={fetchDesigner}
                  DesignerData={DesignerData}
                  setloading={setloading}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default DesignerDashboard;
