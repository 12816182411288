import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Badge, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import ClientBrowseContests from './ClientBrowseContests';
import ClientSettings from './ClientSettings';
import AddContest from './AddContest';
import MyContests from './MyContests';

import ClientNotifications from './ClientNotifications';
import ClientClosedContests from './ClientClosedContests';
import ClientPending from './ClientPending';
import { useAuthContext } from '../../hooks/useAuthContext';

const ClientDashboard = () => {
  const [selected, setselected] = useState('Browse Contests');
  const [ClientData, setClientData] = useState([]);
  const [loading, setloading] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();

  const { dispatch } = useAuthContext();

  // eslint-disable-next-line consistent-return
  const fetchClient = async () => {
    try {
      setloading(true);
      const res = await axios.get(`https://backend.interior-crowd.com/api/v1/clients/${id}`);
      const { data } = res;
      setClientData(data.client);
      const user = await JSON.parse(localStorage.getItem('user'));
      if (data.client.length === 0) {
        localStorage.removeItem('user');
        dispatch({ type: 'LOGOUT' });
        return navigate(`/Login`);
      }
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchClient();
  }, []);

  return (
    <Stack>
      <Box
        className="home-container"
        sx={{
          maxWidth: '1920px',
          width: '100vw',
          minHeight: '30rem',
          display: 'flex',
          // pt: '2rem',
          flexDirection: 'column',
          color: 'white',
        }}
      >
        <Grid
          container
          sx={{
            minHeight: '90vh',
            maxWidth: '100%',
            // border: '1px solid yellow',
          }}
        >
          <CssBaseline />
          <Grid
            container
            item
            sx={{
              // border: '1px solid red',
              flexShrink: 0,
              p: { xs: '4rem 1rem 0 1rem', md: '6rem 1rem' },
              flexDirection: { xs: 'row', md: 'column' },
              backgroundColor: '#333',
            }}
            xs={12}
            md={2}
          >
            <Grid
              item
              onClick={() => setselected('Notifications')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Notifications</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Browse Contests')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Browse Contests</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Settings')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Settings</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('My Contests')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>My Contests</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Pending')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Pending</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Closed Contests')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Closed Contests</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Add Contest')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Add Contest</p>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={10}
            container
            sx={{
              // flexGrow: 1,
              bgcolor: 'background.default',
              // border: '1px solid black',
              flexWrap: 'wrap',
              flexDirection: 'column',
              padding: '6rem 2rem 0 2rem',
            }}
          >
            <Typography textAlign="center" variant="h4" color={'black'}>
              {selected}
              {/* ClientData[0].notifications.length */}
              {!loading && ClientData[0].notifications.length === 0 ? (
                <></>
              ) : (
                <Badge
                  onClick={() => setselected('Notifications')}
                  badgeContent={
                    loading ? <></> : ClientData[0].notifications.length
                  }
                  color="primary"
                  sx={{ cursor: 'pointer', ml: '2rem' }}
                >
                  <NotificationsIcon />
                </Badge>
              )}
            </Typography>
            <Grid
              container
              sx={{
                width: '100%',
              }}
            >
              {selected === 'Browse Contests' && (
                <ClientBrowseContests id={id} />
              )}
              {selected === 'Settings' && (
                <ClientSettings
                  setloading={setloading}
                  fetchClient={fetchClient}
                  loading={loading}
                  ClientData={ClientData}
                />
              )}
              {selected === 'My Contests' && (
                <MyContests
                  fetchClient={fetchClient}
                  loading={loading}
                  ClientData={ClientData}
                />
              )}
              {selected === 'Pending' && (
                <ClientPending
                  fetchClient={fetchClient}
                  loading={loading}
                  ClientData={ClientData}
                />
              )}
              {selected === 'Closed Contests' && (
                <ClientClosedContests
                  fetchClient={fetchClient}
                  loading={loading}
                  ClientData={ClientData}
                />
              )}
              {selected === 'Add Contest' && (
                <AddContest setselected={setselected} id={id} />
              )}
              {selected === 'Notifications' && (
                <ClientNotifications
                  fetchClient={fetchClient}
                  loading={loading}
                  ClientData={ClientData}
                  setloading={setloading}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default ClientDashboard;
