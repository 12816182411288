import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useAuthContext } from '../../hooks/useAuthContext';

const WithdrawForm = ({ DesignerData, setselected, id, paymentContest }) => {
  const [loading, setloading] = useState(false);
  const [alertfail, setalertfail] = useState(false);
  const empty = {
    name: '',
    email: '',
    information: '',
  };
  const [FormInfo, setFormInfo] = useState(empty);
  const { user } = useAuthContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setalertfail(false);
    setloading(true);
    try {
      if (FormInfo === empty) {
        alert('pls add data');
        setloading(false);
        return;
      }
      await axios.post(
        `https://backend.interior-crowd.com/api/v1/payment/designer-payment/${id}`,
        { designer: id, ...FormInfo, contest: paymentContest },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/contests/payment-status/${paymentContest}`,
        {},
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      // post notification to admin
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/admin/add-notification/6648e851b6d247a1f230664d`,
        {
          content: {
            contest: paymentContest,
            text: `You have a new payment request`,
          },
        },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setloading(false);
      setFormInfo(empty);
      setselected('Closed');
      alert('Request Successfully Sent');
    } catch (error) {
      console.log(error);
      setloading(false);
      setalertfail(true);
    }
  };
  const handleChange = (e) => {
    setFormInfo({
      ...FormInfo,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Grid container width="100%" justifyContent="center" alignItems="center">
      <Paper
        elevation={3}
        sx={{
          maxWidth: '35rem',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center',
          m: '2rem 0',
        }}
      >
        <form
          action=""
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container gap={3} sx={{ padding: '0 1.5rem' }}>
            <TextField
              required
              value={FormInfo.name}
              onChange={handleChange}
              name="name"
              fullWidth
              id="standard-basic"
              label="name"
              variant="standard"
            />
            <TextField
              required
              value={FormInfo.email}
              onChange={handleChange}
              name="email"
              type="email"
              fullWidth
              id="standard-basic"
              label="email"
              variant="standard"
            />
            <TextField
              required
              value={FormInfo.information}
              onChange={handleChange}
              name="information"
              fullWidth
              id="standard-basic"
              label="payment information"
              variant="standard"
            />
          </Grid>
          <Grid container justifyContent="center" gap="1rem">
            <Grid container justifyContent="center" gap={2}>
              {loading ? (
                <Button
                  type="submit"
                  disabled
                  variant="contained"
                  sx={{
                    m: '2rem 0 0 0',
                  }}
                >
                  {loading ? <CircularProgress color="success" /> : 'Submit'}
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    m: '2rem 0 0 0',
                  }}
                >
                  {loading ? <CircularProgress color="success" /> : 'Submit'}
                </Button>
              )}
              <Button
                onClick={() => setselected('Closed')}
                sx={{
                  m: '2rem 0 0 0',
                }}
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
            {!loading && alertfail ? (
              <Alert sx={{ mt: '1rem' }} severity="warning">
                Something went wrong.
              </Alert>
            ) : (
              <></>
            )}
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default WithdrawForm;
