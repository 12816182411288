import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useAuthContext } from '../../../hooks/useAuthContext';

const AddEdu = ({ id, settoggleAddEdu, setShowEdu }) => {
  const [loading, setloading] = useState(false);
  const [alertfail, setalertfail] = useState(false);
  const empty = {
    startdate: '',
    enddate: '',
    uniname: '',
    profession: '',
    degree: '',
  };
  const [AddEduData, setAddEduData] = useState(empty);
  const { user } = useAuthContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setalertfail(false);
    setloading(true);
    // const formData = new FormData(e.currentTarget);
    try {
      if (AddEduData === empty) {
        alert('pls add data');
        setloading(false);
        return;
      }
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/designer/add-education/${id}`,
        AddEduData,
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setloading(false);
      setAddEduData(empty);
      alert('Education Successfully Added');

      settoggleAddEdu(false);
      setShowEdu(true);
    } catch (error) {
      setloading(false);
      setalertfail(true);
    }
  };
  const handleChange = (e) => {
    setAddEduData({
      ...AddEduData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Grid container width="100%" justifyContent="center" alignItems="center">
      <Paper
        elevation={3}
        sx={{
          maxWidth: '35rem',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <form
          action=""
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container gap={3} sx={{ padding: '0 1.5rem' }}>
            <Typography component="span" variant="body2">
              Start Date
            </Typography>
            <TextField
              required
              value={AddEduData.startdate}
              onChange={handleChange}
              name="startdate"
              fullWidth
              type="date"
              id="standard-basic"
              variant="standard"
            />
            <Typography component="span" variant="body2">
              End Date
            </Typography>
            <TextField
              value={AddEduData.enddate}
              onChange={handleChange}
              name="enddate"
              fullWidth
              type="date"
              id="standard-basic"
              variant="standard"
            />
            <TextField
              required
              value={AddEduData.uniname}
              onChange={handleChange}
              name="uniname"
              fullWidth
              id="standard-basic"
              label="University / College"
              variant="standard"
            />
            <TextField
              required
              value={AddEduData.profession}
              onChange={handleChange}
              name="profession"
              fullWidth
              id="standard-basic"
              label="location"
              variant="standard"
            />
            <TextField
              value={AddEduData.degree}
              onChange={handleChange}
              name="degree"
              fullWidth
              id="standard-basic"
              label="Degree"
              variant="standard"
            />
          </Grid>
          <Grid container justifyContent="center" gap="1rem">
            <Grid container justifyContent="center" gap={2}>
              {loading ? (
                <Button
                  type="submit"
                  disabled
                  variant="contained"
                  sx={{
                    m: '2rem 0 0 0',
                  }}
                >
                  {loading ? (
                    <CircularProgress color="success" />
                  ) : (
                    'Add New Education'
                  )}
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    m: '2rem 0 0 0',
                  }}
                >
                  {loading ? (
                    <CircularProgress color="success" />
                  ) : (
                    'Add New Education'
                  )}
                </Button>
              )}
              <Button
                onClick={() => {
                  settoggleAddEdu(false);
                  setShowEdu(true);
                }}
                sx={{
                  m: '2rem 0 0 0',
                }}
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
            {!loading && alertfail ? (
              <Alert sx={{ mt: '1rem' }} severity="warning">
                Something went wrong.
              </Alert>
            ) : (
              <></>
            )}
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default AddEdu;
