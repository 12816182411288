import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Card,
  Button,
} from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'axios';
import ImageViewer from 'react-simple-image-viewer';

import { Link, useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import noimg from '../../assets/Home/noimg1.png';
import { useAuthContext } from '../../hooks/useAuthContext';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ClientSingleProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { user } = useAuthContext();

  const [ProjectData, setProjectData] = useState([]);
  const [value, setValue] = React.useState(0);
  const [loading, setloading] = useState(true);
  const [alertloading, setalertloading] = useState(true);
  const [alertfail, setalertfail] = useState(false);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imageData, setimageData] = useState([]);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchProject = async () => {
    try {
      const res = await axios.get(
        `https://backend.interior-crowd.com/api/v1/projects/get-project/${id}`,
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setProjectData(res.data[0]);
      // eslint-disable-next-line
      res.data[0].images.map((entry) => {
        if (imageData.includes(`${entry}`)) {
          return false;
        }
        imageData.push(`${entry}`);
      });
      setloading(false);
      setalertloading(false);
    } catch (error) {
      setloading(false);
      setalertfail(true);
      setalertloading(false);
    }
  };

  const handleAccept = async () => {
    try {
      setalertfail(false);
      setalertloading(true);
      // remove contest from designer applied
      const res = await axios.patch(
        `https://backend.interior-crowd.com/api/v1/designer/delete-project-from-designer/${ProjectData.designer}`,
        { contestID: `${ProjectData.contest._id}` },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      console.log(res);
      // add contest to designer closed
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/designer/add-designer-to-closed/${ProjectData.designer}`,
        { contestID: `${ProjectData.contest._id}` },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      // change status of contest to close
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/contests/my-contests/${ProjectData.contest._id}`,
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      // change status of project to close
      await axios.patch(`https://backend.interior-crowd.com/api/v1/projects/${id}`, {
        headers: {
          'x-auth-token': user.token,
        },
      });
      // assign contest to designer
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/contests/add-designer-to-assigned/${ProjectData.contest._id}`,
        { designerID: `${ProjectData.designer}` },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      // assign projected to contest acceptedproject field
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/contests/add-projected-to-acceptedproject/${ProjectData.contest._id}`,
        { projectID: `${ProjectData._id}` },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      // add contest to closed in client
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/clients/add-closed-contest-to-client/${ProjectData.contest.client}`,
        { contestID: `${ProjectData.contest._id}` },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      // post notification to designer
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/designer/add-notification/${ProjectData.designer}`,
        {
          content: {
            contest: ProjectData.contest._id,
            text: `${ProjectData.contest.title} has been won by you`,
          },
        },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setalertloading(false);
      alert('successfully assigned');
      navigate(`/Single-Contest/${ProjectData.contest._id}`);
    } catch (error) {
      console.log(error, user.token);
      setalertloading(false);
      setalertfail(true);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);

  return (
    <Stack>
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100vw',
          minHeight: '20vh',
          display: 'flex',
          mt: '7rem',
          flexDirection: 'column',
          color: 'white',
        }}
      >
        <Container>
          <Grid container xs={12}>
            <Grid flexDirection="column" container xs={12} md={4}>
              <Box className="S-C-img-container">
                {loading ? (
                  <Grid
                    container
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ width: '100%', height: '100%' }}
                  >
                    <CircularProgress />
                  </Grid>
                ) : (
                  <img
                    className="S-C-img"
                    src={!ProjectData.images[0] ? noimg : ProjectData.images[0]}
                    alt="no img"
                  />
                )}
              </Box>
            </Grid>
            <Grid
              pl={4}
              xs={12}
              sx={{ mt: { xs: '1.5rem', md: 0 } }}
              md={8}
              container
              item
              flexDirection="column"
            >
              <Typography variant="h4">
                {loading ? <>loading</> : ProjectData.title}
              </Typography>
              {/* <Typography variant='body1'> Budget: $ {loading ? <>loading</> : ProjectData.budget}</Typography> */}
              {/* <Typography variant='body1'>{loading ? <>loading</> : ProjectData.deadline}</Typography> */}
              <Link
                style={{ minWidth: '15%', maxWidth: '8rem' }}
                to={`/Single-Designer/${
                  loading ? <>loading</> : ProjectData.designer
                }`}
              >
                <Button sx={{ mt: '1rem', width: '100%' }} variant="contained">
                  Designer
                </Button>
              </Link>
              <Link
                style={{ minWidth: '15%', maxWidth: '8rem' }}
                to={`/Single-Contest/${
                  loading ? <>loading</> : ProjectData.contest._id
                }`}
              >
                <Button sx={{ mt: '1rem', width: '100%' }} variant="contained">
                  Contest
                </Button>
              </Link>
              {/* <Button 
                            onClick={handleAccept} 
                            sx={{mt: '1rem', minWidth: '15%', maxWidth: '8rem'}} 
                            variant='contained'>
                                Accept
                        </Button> */}
              {alertloading ? (
                <Button
                  onClick={handleAccept}
                  disabled
                  sx={{ mt: '1rem', minWidth: '15%', maxWidth: '8rem' }}
                  variant="contained"
                >
                  {alertloading ? (
                    <CircularProgress color="success" />
                  ) : (
                    'Accept'
                  )}
                </Button>
              ) : (
                <Button
                  onClick={handleAccept}
                  sx={{ mt: '1rem', minWidth: '15%', maxWidth: '8rem' }}
                  variant="contained"
                >
                  {alertloading ? (
                    <CircularProgress color="success" />
                  ) : (
                    'Accept'
                  )}
                </Button>
              )}
              {!alertloading && alertfail ? (
                <Alert sx={{ mt: '1rem' }} severity="warning">
                  Something went wrong.
                </Alert>
              ) : (
                <></>
              )}
              <Box
                sx={{
                  width: '100%',
                  minHeight: { xs: '20vh', md: '40rem' },
                  pb: '5rem',
                }}
              >
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'white',
                    color: 'white',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      sx={{ color: 'white' }}
                      label="Images"
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{ color: 'white' }}
                      label="Description"
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{ color: 'white' }}
                      label="Files"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Grid
                    mt={4}
                    container
                    gap={2}
                    item
                    xs={12}
                    justifyContent="space-between"
                  >
                    {loading ? (
                      <>loading</>
                    ) : ProjectData.images.length === 0 ? (
                      <></>
                    ) : (
                      ProjectData.images.map((projectimg, index) => (
                        <Grid
                          key={projectimg._id}
                          item
                          xs={12}
                          sm={5.7}
                          md={3.8}
                        >
                          <Card sx={{ maxWidth: 345 }}>
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                height="200"
                                image={`${projectimg}`}
                                onClick={() => openImageViewer(index)}
                                // eslint-disable-next-line
                                key={index}
                                alt="green iguana"
                              />
                            </CardActionArea>
                          </Card>
                        </Grid>
                      ))
                    )}
                    <div
                      style={{
                        marginTop: '1rem',
                        zIndex: '9999999999999999999',
                      }}
                    >
                      {isViewerOpen && (
                        <ImageViewer
                          src={imageData}
                          currentIndex={currentImage}
                          disableScroll={false}
                          // eslint-disable-next-line
                          closeOnClickOutside={true}
                          onClose={closeImageViewer}
                        />
                      )}
                    </div>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Box>
                    <Typography variant="body1">
                      {loading ? <>loading</> : ProjectData.description}
                    </Typography>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <Grid mt={4} container gap={2} item xs={12}>
                    {loading ? (
                      <>loading</>
                    ) : ProjectData.files.length === 0 ? (
                      <></>
                    ) : (
                      ProjectData.files.map((entry, index) => (
                        <Grid key={entry._id}>
                          <a href={`${entry}`} target="_blank" rel="noreferrer">
                            {' '}
                            <Button variant="contained">
                              File {index + 1}
                            </Button>
                          </a>
                        </Grid>
                      ))
                    )}
                  </Grid>
                </CustomTabPanel>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Stack>
  );
};

export default ClientSingleProject;
