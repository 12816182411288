import {
  Box,
  Container,
  Grid,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LatestPostCard from './LatestPostCard';
import ParticlesComponent from '../Ultils/ParticlesComponent';

const BlogHome = () => {
  const [loading, setloading] = useState(true);
  const [BlogPostData, setBlogPostData] = useState([]);
  const [currPage, setcurrPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(6);
  const lastPostIdx = currPage * postsPerPage;
  const firstPostIdx = lastPostIdx - postsPerPage;
  const currPosts = BlogPostData.slice(firstPostIdx, lastPostIdx);
  const pagCount = Math.round(BlogPostData.length / 6);
  const fetchBlogPosts = async () => {
    try {
      setloading(true);
      const res = await axios.get('https://backend.interior-crowd.com/api/v1/blog/');
      console.log(res.data.post);
      setBlogPostData(res.data.post);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (e, p) => {
    setcurrPage(p);
  };
  useEffect(() => {
    fetchBlogPosts();
  }, []);

  return (
    <Stack>
      <Box
        className="home-container"
        sx={{
          maxWidth: '1920px',
          width: '100vw',
          height: '30rem',
          display: 'flex',
          mt: '2rem',
          flexDirection: 'column',
          color: 'white',
        }}
      >
        <Container>
          <Typography
            mb="2rem"
            maxWidth="15rem"
            sx={{
              backgroundColor: '#29384D',
              padding: '1rem',
              borderRadius: '10px',
              mt: '10rem',
              fontSize: { xs: '1.5rem', md: '1.5rem' },
            }}
            variant="h4"
          >
            Blog
          </Typography>
          <Typography
            sx={{
              maxWidth: { xs: '100%', md: '70%' },
              backgroundColor: '#29384D',
              padding: '1rem',
              borderRadius: '10px',
              fontSize: { xs: '1.25rem', md: '1.25rem' },
            }}
            variant="h5"
          >
            Welcome to Interior Crowd - Where Creativity Meets Opportunity!
          </Typography>
        </Container>
        <Grid xs={12} sx={{ display: 'flex', height: '20rem', zIndex: '-2' }}>
          <ParticlesComponent />
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: '#333', pb: '4rem', pt: '4rem' }}>
        <Container maxWidth="lg">
          <Grid container flexDirection={'column'}>
            <Typography variant="h4" textAlign={'center'}>
              Latest Posts
            </Typography>
            <Grid
              container
              gap={2}
              m="4rem 0"
              item
              xs={12}
              justifyContent="space-between"
            >
              {BlogPostData.length === 0 ? (
                <></>
              ) : (
                BlogPostData.reverse()
                  .slice(0, 3)
                  .map((post) => (
                    <LatestPostCard
                      loading={loading}
                      key={post._id}
                      post={post}
                    />
                  ))
              )}
            </Grid>
          </Grid>
          <Grid container flexDirection={'column'}>
            <Typography variant="h4" textAlign={'center'}>
              All Posts
            </Typography>
            <Grid
              container
              gap={2}
              m="4rem 0"
              item
              xs={12}
              justifyContent="space-between"
            >
              {currPosts.length === 0 ? (
                <></>
              ) : (
                currPosts.map((post) => (
                  <LatestPostCard
                    loading={loading}
                    key={post._id}
                    post={post}
                  />
                ))
              )}
            </Grid>
            <Grid container justifyContent={'center'} sx={{ color: 'white' }}>
              <Pagination
                onChange={handleChange}
                count={pagCount}
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Stack>
  );
};

export default BlogHome;
