import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import React, { useState, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';

const AllProjects = () => {
  const { id } = useParams();

  const [contestData, setcontestData] = useState([]);
  const [loading, setloading] = useState(true);

  const fetchContest = async () => {
    try {
      const res = await axios.get(
        `https://backend.interior-crowd.com/api/v1/contests/${id}`,
      );
      setcontestData(res.data[0]);
      console.log(contestData);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchContest();
  }, []);

  return (
    <Stack>
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100vw',
          minHeight: { xs: '20vh', md: '30rem' },
          display: 'flex',
          m: '8rem 0 4rem 0',
          flexDirection: 'column',
          color: 'white',
        }}
      >
        <Container>
          <Grid
            container
            flexDirection="column"
            alignItems="center"
            item
            xs={12}
          >
            <Typography variant="h4">
              All Projects Submitted for{' '}
              {loading ? <>loading</> : contestData.title}
            </Typography>
            <Grid container item xs={12} justifyContent="center" mt={4}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell align="right">Designer</TableCell>
                      <TableCell align="right">View Proposal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <>loading</>
                    ) : contestData.projects.length === 0 ? (
                      <>no data</>
                    ) : (
                      contestData.projects.map((entry) => (
                        <TableRow
                          key={entry._id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="entry">
                            {entry.title}
                          </TableCell>
                          <TableCell align="right">
                            <Link to={`/Single-Designer/${entry.designer}`}>
                              <Button variant="contained">View Designer</Button>
                            </Link>
                          </TableCell>
                          <TableCell align="right">
                            <Link to={`/Client-Single-Project/${entry._id}`}>
                              <Button variant="contained">View Proposal</Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Stack>
  );
};

export default AllProjects;
