import { Box, Container, Stack } from '@mui/material';
import React from 'react';

const PaymentSuccess = () => (
  <Stack>
    <Box
      className="home-container"
      sx={{
        maxWidth: '1920px',
        width: '100vw',
        maxHeight: '100vh',
        height: '70vh',
        display: 'flex',
        mt: '2rem',
        flexDirection: 'column',
        color: 'white',
      }}
    >
      <Container maxWidth="xl">asd</Container>
    </Box>
  </Stack>
);

export default PaymentSuccess;
