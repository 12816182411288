import { Button, Grid, Paper, TextField } from '@mui/material';
import React, { useState } from 'react';

import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useAuthContext } from '../../../hooks/useAuthContext';

const DesignerEditInfo = ({ DesignerData, seteditInfo }) => {
  const [loading, setloading] = useState(false);
  const [alertfail, setalertfail] = useState(false);
  const [UpdatedInfoData, setUpdatedInfoData] = useState();
  const { user } = useAuthContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setalertfail(false);
    setloading(true);
    try {
      if (!UpdatedInfoData) {
        alert('pls add data or cancel');
        setloading(false);
        return;
      }
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/designer/update-info/${DesignerData[0]._id}`,
        UpdatedInfoData,
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setloading(false);
      seteditInfo(false);
    } catch (error) {
      setloading(false);
      setalertfail(true);
    }
  };
  const handleChange = (e) => {
    setUpdatedInfoData({
      ...UpdatedInfoData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Grid container width="100%" justifyContent="center" alignItems="center">
      <Paper
        elevation={3}
        sx={{
          maxWidth: '35rem',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <form
          action=""
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container gap={3} sx={{ padding: '0 1.5rem' }}>
            <TextField
              onChange={handleChange}
              name="name"
              fullWidth
              id="standard-basic"
              label={DesignerData[0].name}
              variant="standard"
            />
            <TextField
              onChange={handleChange}
              name="surname"
              fullWidth
              id="standard-basic"
              label={DesignerData[0].surname}
              variant="standard"
            />
            <TextField
              onChange={handleChange}
              name="profession"
              fullWidth
              id="standard-basic"
              label={DesignerData[0].profession}
              variant="standard"
            />
            <TextField
              onChange={handleChange}
              name="location"
              fullWidth
              id="standard-basic"
              label={DesignerData[0].location}
              variant="standard"
            />
          </Grid>
          {/* <Grid container mt="1rem" item xs={12}>
            {Images.length === 0 ? (
              <Button variant="contained" component="label">
                Upload Profile Photo
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    setImages(e.target.files);
                  }}
                />
              </Button>
            ) : (
              <>
                <Button variant="contained" component="label">
                  Upload Profile Photo
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      setImages(e.target.files);
                    }}
                  />
                </Button>
                <p style={{ marginTop: '1rem', marginLeft: '1rem' }}>
                  {Images.name} Selected.
                </p>
              </>
            )}
          </Grid>
          <Grid container mt="1rem" item xs={12}>
            {coverPic.length === 0 ? (
              <Button variant="contained" component="label">
                Upload Cover Photo
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    setcoverPic(e.target.files);
                  }}
                />
              </Button>
            ) : (
              <>
                <Button variant="contained" component="label">
                  Upload Cover Photo
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      setcoverPic(e.target.files);
                    }}
                  />
                </Button>
                <p style={{ marginTop: '1rem', marginLeft: '1rem' }}>
                  {coverPic.name} Selected.
                </p>
              </>
            )}
          </Grid> */}
          <Grid container justifyContent="center" gap="1rem">
            {loading ? (
              <Button
                type="submit"
                disabled
                variant="contained"
                sx={{
                  m: '2rem 0 0 0',
                }}
              >
                {loading ? <CircularProgress color="success" /> : 'Update Info'}
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{
                  m: '2rem 0 0 0',
                }}
              >
                {loading ? <CircularProgress color="success" /> : 'Update Info'}
              </Button>
            )}
            {!loading && alertfail ? (
              <Alert sx={{ mt: '1rem' }} severity="warning">
                Something went wrong.
              </Alert>
            ) : (
              <></>
            )}
            <Button
              sx={{
                m: '2rem 0 0 0',
              }}
              onClick={() => seteditInfo(false)}
              variant="contained"
            >
              Cancel
            </Button>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default DesignerEditInfo;
