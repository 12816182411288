import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Card,
} from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';

import LoadingComponent from '../Ultils/LoadingComponent';
// import ParticlesComponent from '../Ultils/ParticlesComponent';

import logo from '../../assets/footer/logo_transparent.png';
import noimg from '../../assets/Home/noimg1.png';
import svg1 from '../../assets/Home/s1.svg';
import svg2 from '../../assets/Home/svg2.svg';
import svg3 from '../../assets/Home/svg3.svg';

const Home = () => {
  const [designers, setDesigners] = useState([]);
  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchDesigners = async () => {
    try {
      const res = await axios.get('https://backend.interior-crowd.com/api/v1/designer');

      setDesigners(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContests = async () => {
    try {
      const res = await axios.get('https://backend.interior-crowd.com/api/v1/contests');

      setContests(res.data.contests);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDesigners();
    fetchContests();
  }, []);

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          backgroundColor: '#29384D',
          color: 'white',
          py: '5rem',
          textAlign: 'center',
          pt: '8rem',
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h3" mb={2}>
            THE NEW WAY TO REDESIGN YOUR SPACE ONLINE
          </Typography>
          <Typography variant="h5" mb={4}>
            Get dozens of high-quality designs: easy, fast, and on a budget.
          </Typography>
          <Link to="/Login" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary" size="large">
              GO TO REGISTRATION PAGE
            </Button>
          </Link>
        </Container>
        {/* <ParticlesComponent /> */}
      </Box>

      {/* Browse Contests Section */}
      <Box sx={{ backgroundColor: '#F8F9FA', py: '5rem' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" mb={4} align="center" color={'black'}>
            Browse Contests
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {loading ? (
              <LoadingComponent />
            ) : (
              contests.slice(0, 3).map((contest) => (
                <Grid key={contest._id} item xs={12} sm={6} md={4}>
                  <Link to={`/Single-Contest/${contest._id}`}>
                    <Card sx={{ height: '100%' }}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          image={contest.images[0] || noimg}
                          alt={contest.title}
                          height="200"
                          sx={{ objectFit: 'cover', maxHeight: '100%' }}
                        />
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            {contest.title}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {contest.location}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Type: {contest.category}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Link>
                </Grid>
              ))
            )}
          </Grid>
          <Box mt={4} textAlign="center">
            <Link to="/Contests" style={{ textDecoration: 'none' }}>
              <Button variant="outlined" color="primary">
                Browse More
              </Button>
            </Link>
          </Box>
        </Container>
      </Box>

      {/* How it works Section */}
      <Box sx={{ py: '5rem', textAlign: 'center' }}>
        <Typography variant="h4" mb={4}>
          How It Works!
        </Typography>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={4}>
            <img
              src={svg1}
              alt="Step 1"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
            <Typography variant="h5" mt={2}>
              Upload Your Contest
            </Typography>
            <Typography variant="body1">
              Provide details for your space, and your project is posted for the
              community.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <img
              src={svg2}
              alt="Step 2"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
            <Typography variant="h5" mt={2}>
              Work with Professionals
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <img
              src={svg3}
              alt="Step 3"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
            <Typography variant="h5" mt={2}>
              Get your designs
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Browse Designers Section */}
      <Box sx={{ backgroundColor: '#F8F9FA', py: '5rem' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" mb={4} align="center" color={'black'}>
            Designers
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {loading ? (
              <LoadingComponent />
            ) : (
              designers.slice(0, 3).map((designer) => (
                <Grid key={designer._id} item xs={12} sm={6} md={4}>
                  <Link to={`/Single-Designer/${designer._id}`}>
                    <Card sx={{ height: '100%' }}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          image={designer.coverPic[0] || noimg}
                          alt={`${designer.name} ${designer.surname}`}
                          height="200"
                          sx={{ objectFit: 'cover', maxHeight: '100%' }}
                        />
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            {designer.name} {designer.surname}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {designer.profession} | {designer.location}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Link>
                </Grid>
              ))
            )}
          </Grid>
          <Box mt={4} textAlign="center">
            <Link to="/Designers" style={{ textDecoration: 'none' }}>
              <Button variant="outlined" color="primary">
                Browse More
              </Button>
            </Link>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
