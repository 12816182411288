import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { Button, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import ClientEditInfo from './Client-Update/ClientEditInfo';
import { useAuthContext } from '../../hooks/useAuthContext';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ClientSettings = ({ ClientData, loading, fetchClient, setloading }) => {
  const [value, setValue] = React.useState(0);
  const [toggleEdit, settoggleEdit] = React.useState(false);
  const [alertfail, setalertfail] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { user } = useAuthContext();

  const handleUploadPic = async (e) => {
    try {
      setloading(true);
      setalertfail(true);
      // setImages(e.target.files);
      const formData = new FormData();
      formData.append('clientID', ClientData[0]._id);
      formData.append('profilePic', e.target.files[0]);
      const res = await axios.post(
        `https://backend.interior-crowd.com/api/v1/client/update-client-profilepic`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': user.token,
          },
        },
      );
      // console.log(...formData);
      setloading(false);
      setalertfail(false);
      alert('uploaded');
    } catch (error) {
      setalertfail(true);
      console.log(error);
    }
  };

  const handleCoverPic = async (e) => {
    try {
      setloading(true);
      setalertfail(true);
      // setImages(e.target.files);
      const formData = new FormData();
      formData.append('clientID', ClientData[0]._id);
      formData.append('coverPic', e.target.files[0]);
      const res = await axios.post(
        `https://backend.interior-crowd.com/api/v1/client/update-client-coverpic`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': user.token,
          },
        },
      );
      setloading(false);
      setalertfail(false);
      alert('uploaded');
    } catch (error) {
      setalertfail(true);
    }
  };

  React.useEffect(() => {
    fetchClient();
  }, [toggleEdit]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Account Info" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid container xs={12} flexDirection="column">
          {toggleEdit ? (
            <ClientEditInfo
              ClientData={ClientData}
              loading={loading}
              settoggleEdit={settoggleEdit}
            />
          ) : (
            <>
              <Grid container mt="1rem" justifyContent="space-between">
                <Grid item xs={12} sm={4}>
                  <Typography
                    sx={{ mb: '1rem' }}
                    color={'black'}
                    variant="body1"
                  >
                    Member Since:{' '}
                    {loading ? <>loading</> : ClientData[0].createdAt}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  container
                  justifyContent="flex-end"
                  gap="1rem"
                >
                  <Button
                    onClick={() => settoggleEdit(true)}
                    variant="contained"
                  >
                    Edit
                  </Button>
                  {loading ? (
                    <Button disabled variant="contained">
                      <CircularProgress color="success" />
                    </Button>
                  ) : (
                    <Button variant="contained" component="label">
                      Upload Profile Photo
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          handleUploadPic(e);
                        }}
                      />
                    </Button>
                  )}
                  {loading ? (
                    <Button disabled variant="contained">
                      <CircularProgress color="success" />
                    </Button>
                  ) : (
                    <Button variant="contained" component="label">
                      Upload Cover Photo
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          handleCoverPic(e);
                        }}
                      />
                    </Button>
                  )}
                  {!loading && alertfail ? (
                    <Alert sx={{ mt: '1rem' }} severity="warning">
                      Something went wrong.
                    </Alert>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>

              <Typography color={'black'} variant="h4">
                {loading ? <>loading</> : `${ClientData[0].name}`}{' '}
                {ClientData[0].surname}
              </Typography>
              <Typography color={'black'} variant="body1">
                {loading ? <>loading</> : `email: ${ClientData[0].email}`}
              </Typography>
              <Typography color={'black'} variant="body1">
                {loading ? <>loading</> : `phone: ${ClientData[0].phone}`}
              </Typography>
            </>
          )}
        </Grid>
      </CustomTabPanel>
    </Box>
  );
};

export default ClientSettings;
