import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useAuthContext } from '../../hooks/useAuthContext';

const DesignerRegisteration = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [designerData, setdesignerData] = useState({});
  const [loading, setloading] = useState(false);
  const [alertfail, setalertfail] = useState(false);

  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  const registerDesigner = async (e) => {
    e.preventDefault();
    setalertfail(false);
    setloading(true);
    try {
      const res = await axios.post(
        'https://backend.interior-crowd.com/api/v1/designer/',
        designerData,
      );
      const token = res.data;
      localStorage.setItem('user', JSON.stringify(token));
      dispatch({ type: 'LOGIN', payload: token });
      setloading(false);
      navigate(`/Designer-Dashboard/${token.user}`);
    } catch (error) {
      setloading(false);
      setalertfail(true);
    }
  };

  const handleChange = (e) => {
    setdesignerData({
      ...designerData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box
      className="login-container"
      sx={{
        height: '100%',
        maxWidth: '1920px',
        width: '100vw',
        padding: '5rem 0',
      }}
    >
      <Grid
        container
        width="100%"
        minHeight="80vh"
        justifyContent="center"
        alignItems="center"
      >
        <Paper
          elevation={3}
          sx={{
            width: '35rem',
            padding: '1rem',
            display: 'flex',
            mt: '2rem',
            flexDirection: 'column',
            gap: '0.5rem',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4">Sign up</Typography>
          <form
            action=""
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
            onSubmit={registerDesigner}
          >
            <Grid container gap={3} sx={{ padding: '0 1.5rem' }}>
              <TextField
                required
                name="email"
                onChange={handleChange}
                fullWidth
                type="email"
                id="standard-basic"
                label="Email"
                variant="standard"
              />
              <TextField
                required
                name="name"
                onChange={handleChange}
                fullWidth
                id="standard-basic"
                label="Name"
                variant="standard"
              />
              <TextField
                required
                name="surname"
                onChange={handleChange}
                fullWidth
                id="standard-basic"
                label="Sur Name"
                variant="standard"
              />
              <TextField
                required
                name="profession"
                onChange={handleChange}
                fullWidth
                id="standard-basic"
                label="profession"
                variant="standard"
              />
              <TextField
                required
                name="location"
                onChange={handleChange}
                fullWidth
                id="standard-basic"
                label="location"
                variant="standard"
              />
              <FormControl sx={{ width: '100%' }} variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <Input
                  required
                  onChange={handleChange}
                  name="password"
                  id="standard-adornment-password"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl sx={{ width: '100%' }} variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Confirm Password
                </InputLabel>
                <Input
                  required
                  id="standard-adornment-password"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Box
                sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}
              >
                <PhoneIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  required
                  name="phone"
                  onChange={handleChange}
                  type="number"
                  fullWidth
                  label="Phone Number"
                  variant="standard"
                />
              </Box>
            </Grid>
            {loading ? (
              <Button
                type="submit"
                disabled
                variant="contained"
                sx={{
                  m: '2rem 0 0 0',
                }}
              >
                {loading ? <CircularProgress color="success" /> : 'Sign Up'}
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{
                  m: '2rem 0 0 0',
                }}
              >
                {loading ? <CircularProgress color="success" /> : 'Sign Up'}
              </Button>
            )}
            {!loading && alertfail ? (
              <Alert sx={{ mt: '1rem' }} severity="warning">
                email already registered.
              </Alert>
            ) : (
              <></>
            )}
          </form>
          <Link to="/lost-password">
            <Typography variant="caption">Forgot your password?</Typography>
          </Link>
          <Typography variant="caption">Already registered?</Typography>
          <Link to="/Login">
            <Button
              variant="contained"
              sx={{
                m: '0 0 1.5rem 0',
              }}
            >
              Sign in
            </Button>
          </Link>
        </Paper>
      </Grid>
    </Box>
  );
};

export default DesignerRegisteration;
