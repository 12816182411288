import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useAuthContext } from '../../hooks/useAuthContext';

const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [loginData, setloginData] = useState({});
  const [loading, setloading] = useState(false);
  const [alertfail, setalertfail] = useState(false);

  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  const loginFunc = async (e) => {
    e.preventDefault();
    setalertfail(false);
    setloading(true);
    await axios
      .post('https://backend.interior-crowd.com/api/v1/clients/login', loginData)
      .then((res) => {
        const token = res.data;
        localStorage.setItem('user', JSON.stringify(token));
        dispatch({ type: 'LOGIN', payload: token });
        setloading(false);
        navigate(`/Client-Dashboard/${token.user}`);
      })
      .catch((err) => {
        setloading(false);
        setalertfail(true);
      });
  };

  const handleChange = (e) => {
    setloginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      className="login-container"
      sx={{
        maxHeight: '80vh',
        height: '100%',
        mt: '2rem',
        maxWidth: '1920px',
        width: '100vw',
      }}
    >
      <Grid
        container
        width="100%"
        minHeight="80vh"
        justifyContent="center"
        alignItems="center"
      >
        <Paper
          elevation={3}
          sx={{
            width: '25rem',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4">Client</Typography>
          <form
            action=""
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
            onSubmit={loginFunc}
          >
            <Grid container gap={3} sx={{ padding: '0 1.5rem' }}>
              <TextField
                required
                name="email"
                onChange={handleChange}
                fullWidth
                type="email"
                id="standard-basic"
                label="Email"
                variant="standard"
              />
              <Grid item sx={{ width: '100%' }}>
                <FormControl sx={{ width: '100%' }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-password">
                    Password
                  </InputLabel>
                  <Input
                    required
                    name="password"
                    onChange={handleChange}
                    id="standard-adornment-password"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            {loading ? (
              <Button
                type="submit"
                disabled
                variant="contained"
                sx={{
                  m: '2rem 0 0 0',
                }}
              >
                {loading ? <CircularProgress color="success" /> : 'Sign in'}
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{
                  m: '2rem 0 0 0',
                }}
              >
                {loading ? <CircularProgress color="success" /> : 'Sign in'}
              </Button>
            )}
            {!loading && alertfail ? (
              <Alert sx={{ mt: '1rem' }} severity="warning">
                Email or password is wrong.
              </Alert>
            ) : (
              <></>
            )}
          </form>
          <Link to="/lost-password">
            <Typography variant="caption">Forgot your password?</Typography>
          </Link>
          <Typography variant="caption">Not registered yet?</Typography>
          <Link to="/role">
            <Button
              variant="contained"
              sx={{
                m: '0 0 1.5rem 0',
              }}
            >
              Sign up
            </Button>
          </Link>
        </Paper>
      </Grid>
    </Box>
  );
};

export default Login;
