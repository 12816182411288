/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Card,
} from '@mui/material';
import LoadingComponent from '../Ultils/LoadingComponent';
import AOS from 'aos';
import 'aos/dist/aos.css';
import pic1 from '../../assets/Home/pic1.png';
import pic2 from '../../assets/Home/pic2.jpg';
import pic3 from '../../assets/Home/pic3.jpg';
import './newHome.css';
import NewFooter from './NewFooter';

const NewHome = () => {
  const [Designers, setDesigners] = useState([]);
  const [Contests, setContests] = useState([]);
  const [loading, setloading] = useState(true);
  const fetchDesigners = async () => {
    try {
      const res = await axios.get(
        'https://backend.interior-crowd.com/api/v1/designer',
      );

      setDesigners(res.data);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchContests = async () => {
    try {
      const res = await axios.get(
        'https://backend.interior-crowd.com/api/v1/contests',
      );

      setContests(res.data.contests);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AOS.init();
    fetchDesigners();
    fetchContests();
  }, []);
  let id;
  return (
    <>
      <main className="main arsha-top">
        <section id="hero" className="hero section">
          <div className="container">
            <div className="row gy-10">
              <div
                className="col-12 col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center"
                data-aos="zoom-out"
              >
                <h1 className="">
                  Unlock countless designs for the Price of just one!
                </h1>
                <p className="">
                  Get multiple designs from architectural, interior and
                  landscape designers all over the world.
                </p>
                <div className="d-flex">
                  <Link to="/Role" className="btn-get-started">
                    Get Started
                  </Link>
                  <a
                    href="https://youtu.be/ow6g9rmhfr8"
                    className="glightbox btn-watch-video d-flex align-items-center"
                  >
                    <i className="bi bi-play-circle" />
                    <span>Watch Video</span>
                  </a>
                </div>
              </div>
              <div
                className="col-12 col-lg-6 order-1 order-lg-2 hero-img"
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <img src={pic1} className="img-fluid animated" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services section">
          <div className="container section-title" data-aos="fade-up">
            <h2>How it Works</h2>
          </div>

          <div className="container">
            <div className="row gy-4">
              <div
                className="col-xl-4 col-md-6 d-flex service-column"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="bi bi-activity icon" />
                  </div>
                  <h4>
                    <Link to="/Contests" className="stretched-link">
                      Describe Your Project
                    </Link>
                  </h4>
                  <p>
                    Identify your design challenge and embark on exciting
                    ventures such as remodeling, new residential builds,
                    interior design, landscaping, or commercial/public projects.
                  </p>
                </div>
              </div>

              <div
                className="col-xl-4 col-md-6 d-flex service-column"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="bi bi-bounding-box-circles icon" />
                  </div>
                  <h4>
                    <Link to="/Designers" className="stretched-link">
                      2. Kick Off Your Contest
                    </Link>
                  </h4>
                  <p>
                    Dive into the exciting contest experience as designers from
                    around the world compete to bring your project to life on
                    Interior-crowd's trusted platform.
                  </p>
                </div>
              </div>

              <div
                className="col-xl-4 col-md-6 d-flex service-column"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="bi bi-calendar4-week icon" />
                  </div>
                  <h4>
                    <a href="#" className="stretched-link">
                      3. Pick Best Design
                    </a>
                  </h4>
                  <p>
                    Browse through a variety of innovative proposals from
                    talented designers and select the one that perfectly aligns
                    with your vision and requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="portfolio" className="portfolio section">
          <div className="container section-title" data-aos="fade-up">
            <h2>LIVE CONTESTS</h2>
            <p>
              Get inspired by the dozens of contests and the hundreds of
              projects uploaded on Interior Crowd every day.
            </p>
          </div>
          <Container>
            <Grid
              container
              alignItems="center"
              flexDirection="column"
              item
              xs={12}
            >
              <Grid
                container
                gap={2}
                item
                xs={12}
                justifyContent="space-between"
              >
                {loading ? (
                  <LoadingComponent />
                ) : Contests.length === 0 ? (
                  <></>
                ) : (
                  Contests.slice(0, 6).map((contest) => (
                    <Grid key={contest._id} item xs={12} sm={5.7} md={3.7}>
                      <Link to={`/Single-Contest/${contest._id}`}>
                        <Card sx={{ maxWidth: 345 }}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="200"
                              image={
                                loading || !contest.images[0]
                                  ? noimg
                                  : `${contest.images[0]}`
                              }
                              alt="green iguana"
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                {contest.title}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {contest.location}
                              </Typography>
                              <Grid container item xs={12} mt={1}>
                                <Grid item xs={6}>
                                  <Typography variant="body2">
                                    Type: {contest.category}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    variant="body2"
                                    color={'green'}
                                    fontWeight={600}
                                  >
                                    Budget: ${contest.budget}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Link>
                    </Grid>
                  ))
                )}
              </Grid>
              <Link to="/Contests">
                <Button variant="contained" sx={{ m: '4rem 0' }}>
                  Browse More
                </Button>
              </Link>
            </Grid>
          </Container>
        </section>

        <section id="skills" className="skills section">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <img src={pic2} className="img-fluid" alt="" />
              </div>

              <div className="col-lg-6 pt-4 pt-lg-0 content">
                <h3>SPACEOWNER :</h3>
                <p className="fst-italic">
                  Are you a space owner ready to embark on your creative
                  journey? Whether you're planning a residential, retail, or
                  interior project, big or small, Interior Crowd is here to
                  support you every step of the way. Post your project today and
                  receive multiple design proposals for the price of one!
                </p>
                <Link className="cta-btn align-middle" to="/Role">
                  <Button variant="contained">Sign Up</Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section id="why-us" className="section why-us" data-builder="section">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-lg-7 d-flex flex-column justify-content-center order-2 order-lg-1">
                <div
                  className="content px-xl-5"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <h3 className="">
                    <span className="">WHAT YOU WILL </span>
                    <strong>GET ?</strong>
                  </h3>
                  <ul style={{ color: 'white', listStyle: 'none' }}>
                    <li style={{ paddingTop: '16px' }}>
                      <i className="bi bi-check2-circle" />{' '}
                      <span>Multiple design proposals for comparison</span>
                    </li>
                    <li style={{ paddingTop: '16px' }}>
                      <i className="bi bi-check2-circle" />{' '}
                      <span>
                        Access to a diverse pool of talented designers
                      </span>
                    </li>
                    <li style={{ paddingTop: '16px' }}>
                      <i className="bi bi-check2-circle" />{' '}
                      <span>
                        The ability to choose the design that best fits their
                        vision
                      </span>
                    </li>
                    <li style={{ paddingTop: '16px' }}>
                      <i className="bi bi-check2-circle" />{' '}
                      <span>
                        A streamlined process for managing and reviewing
                        proposals
                      </span>
                    </li>
                    <li style={{ paddingTop: '16px' }}>
                      <i className="bi bi-check2-circle" />{' '}
                      <span>
                        Continuous support and guidance throughout the project
                      </span>
                    </li>
                  </ul>
                  <h3 style={{ paddingTop: '16px', color: '#20B2AA' }}>
                    AND ALL THIS FOR THE PRICE OF ONLY ONE PROJECT!
                  </h3>
                </div>
              </div>

              <div className="col-lg-5 order-1 order-lg-2 why-us-img">
                <img
                  src={pic3}
                  className="img-fluid"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay="100"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="team" className="team section">
          <div className="container section-title" data-aos="fade-up">
            <h2>OUR DESIGNERS</h2>
            <p>
              The best design professionals in the world, ready to help you
              renovate your house, your office, your space.
            </p>
          </div>
          <Box sx={{ backgroundColor: '#333' }}>
            <Container>
              <Grid
                container
                flexDirection="column"
                item
                xs={12}
                mt="4rem"
                alignItems="center"
              >
                <Grid
                  container
                  gap={2}
                  m="4rem 0"
                  item
                  xs={12}
                  justifyContent="space-between"
                >
                  {loading ? (
                    <LoadingComponent />
                  ) : Designers.length === 0 ? (
                    <></>
                  ) : (
                    Designers.slice(0, 3).map((desinger) => (
                      <Grid key={desinger._id} item xs={12} sm={5.7} md={3.7}>
                        <Link to={`/Single-Designer/${desinger._id}`}>
                          <Card sx={{ maxWidth: 345 }}>
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                height="140"
                                image={
                                  loading || !desinger.coverPic[0]
                                    ? 'https://d1s5wdh0uf8y8f.cloudfront.net/1400x420/designers/5c2de4b6b7172d00257543cb/f9WiaASyaaFgnbK5ulkA_open space-0004.jpg'
                                    : `${desinger.coverPic[0]}`
                                }
                                alt="green iguana"
                              />
                              <CardContent>
                                <Grid container item xs={12}>
                                  <Grid item xs={8}>
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      component="div"
                                    >
                                      {desinger.name} {desinger.surname}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {desinger.profession} |{' '}
                                      {desinger.location}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Box className="D-Profile-pic-container">
                                      <img
                                        className="D-Profile-pic"
                                        src={
                                          loading || !desinger.profilePic[0]
                                            ? 'https://d1s5wdh0uf8y8f.cloudfront.net/200x200/designers/5c2de4b6b7172d00257543cb/JoIxS028Q9qgx6hxYAI6_Giacomo Cattani foto.jpg'
                                            : `${desinger.profilePic[0]}`
                                        }
                                        alt=""
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Link>
                      </Grid>
                    ))
                  )}
                </Grid>
                <Link to="/Designers">
                  <Button variant="contained" sx={{ m: '2.5rem 0 4rem 0' }}>
                    Browse More
                  </Button>
                </Link>
              </Grid>
            </Container>
          </Box>
        </section>

        <section id="about" className="about section">
          <div className="container section-title" data-aos="fade-up">
            <h2 className="">About Us</h2>
          </div>

          <div className="container">
            <div className="row gy-4">
              <div
                className="col-lg-6 content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <p>
                  At Interior Crowd, we believe in the power of collaboration
                  and the endless possibilities of creativity.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check2-circle" />{' '}
                    <span>
                      Our platform serves as a vibrant marketplace where clients
                      and designers converge
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-check2-circle" />{' '}
                    <span>
                      Interior Crowd was born out of a passion for connecting
                      talented designers with clients.
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-check2-circle" />{' '}
                    <span>
                      At Interior Crowd, we facilitate a seamless process for
                      clients to submit their projects and for designers to
                      submit their proposals.
                    </span>
                  </li>
                </ul>
              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <p>
                  At Interior Crowd, we believe in the power of collaboration
                  and the endless possibilities of creativity. Our platform
                  serves as a vibrant marketplace where clients and designers
                  converge to bring ideas to life and create something
                  extraordinary together.{' '}
                </p>
                <Link to="/About-Us" className="read-more">
                  <span>Read More</span>
                  <i className="bi bi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section id="call-to-action" className="call-to-action section">
          <img src="/img/cta-bg.jpg" alt="" />

          <div className="container">
            <div className="row" data-aos="zoom-in" data-aos-delay="100">
              <div className="col-xl-9 text-center text-xl-start">
                <h3>Join Us Today:</h3>
                <p>
                  Ready to embark on your creative journey? Whether you're a
                  client ready to kickstart your project or a designer eager to
                  showcase your talents, Interior Crowd is here to support you
                  every step of the way. Join our community today and experience
                  the magic of collaboration like never before.
                </p>
              </div>
              <div className="col-xl-3 cta-btn-container text-center">
                <Link className="cta-btn align-middle" to="/Role">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default NewHome;
