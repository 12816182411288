import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';

const SingleBlogPost = () => {
  const [postData, setpostData] = useState([]);
  const [loading, setloading] = useState(true);
  const { title, id } = useParams();
  const fetchPost = async () => {
    try {
      const res = await axios.get(`https://backend.interior-crowd.com/api/v1/blog/${id}`);
      setpostData(res.data.post);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPost();
  }, []);

  return (
    <Grid
      className="blog-post-container"
      container
      flexDirection={'column'}
      gap={'2rem'}
      alignItems={'center'}
      sx={{ padding: { xs: '0 0 2rem 0', sm: '0 1rem 2rem 1rem' } }}
    >
      <Grid
        className="blog-post-titleImg-container"
        container
        item
        xs={12}
        sm={8}
      >
        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: '#333',
              zIndex: '22',
              width: '100%',
              height: '300px',
            }}
          >
            <CircularProgress color="success" />
          </Grid>
        ) : (
          <img
            src={
              postData[0].titleImg
                ? postData[0].titleImg
                : 'https://d1s5wdh0uf8y8f.cloudfront.net/1400x420/designers/5c2de4b6b7172d00257543cb/f9WiaASyaaFgnbK5ulkA_open space-0004.jpg'
            }
            alt="no img"
          />
        )}
      </Grid>
      <Grid
        container
        flexDirection={'column'}
        alignItems={'center'}
        gap={'2rem'}
      >
        <Container className="blog-content-container text-center">
          <Typography variant="h3" sx={{ textAlign: 'center', m: '1rem 0' }}>
            {loading ? '' : postData[0].title}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center', m: '1rem 0' }}>
            {loading ? '' : postData[0].description}
          </Typography>
          {loading ? '' : parse(postData[0].content)}
        </Container>
      </Grid>
    </Grid>
  );
};

export default SingleBlogPost;
