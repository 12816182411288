import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Card,
  Chip,
  Paper,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'axios';

import LoadingComponent from '../Ultils/LoadingComponent';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const SingleDesigner = () => {
  const { id } = useParams();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [loading, setloading] = useState(true);
  const [Designer, setDesigner] = useState([]);
  const fetchDesigner = async () => {
    try {
      const res = await axios.get(
        `https://backend.interior-crowd.com/api/v1/designer/${id}`,
      );

      setDesigner(res.data);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDesigner();
    console.log(Designer);
  }, []);

  // if (loading) {
  //   return <div style={{marginTop: '5rem', minHeight: '50vh', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}><h1>loading</h1></div>
  // }
  return (
    <Stack>
      <Box
        className="home-container"
        sx={{
          maxWidth: '1920px',
          width: '100vw',
          minHeight: '20vh',
          display: 'flex',
          mt: '4rem',
          flexDirection: 'column',
          color: 'white',
          alignItems: 'center',
        }}
      >
        <Box className="single-D-img-container">
          {loading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: '#333',
                zIndex: '22',
                width: '100%',
                height: '100%',
              }}
            >
              <CircularProgress color="success" />
            </Grid>
          ) : (
            <img
              className="single-D-img-pic"
              src={
                Designer[0].coverPic[0]
                  ? Designer[0].coverPic[0]
                  : 'https://d1s5wdh0uf8y8f.cloudfront.net/1400x420/designers/5c2de4b6b7172d00257543cb/f9WiaASyaaFgnbK5ulkA_open space-0004.jpg'
              }
              alt=""
            />
          )}
        </Box>
      </Box>
      <div className="S-D-Profile-pic-container">
        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: '#333',
              zIndex: '22',
              width: '100%',
              height: '100%',
              borderRadius: '50%',
            }}
          >
            <CircularProgress color="success" />
          </Grid>
        ) : (
          <img
            className="S-D-Profile-pic"
            src={
              Designer[0].profilePic[0]
                ? Designer[0].profilePic[0]
                : 'https://d1s5wdh0uf8y8f.cloudfront.net/200x200/designers/5c2de4b6b7172d00257543cb/JoIxS028Q9qgx6hxYAI6_Giacomo Cattani foto.jpg'
            }
            alt=""
          />
        )}
      </div>
      <Container
        maxWidth="lg"
        sx={{
          mt: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.25rem',
        }}
      >
        <Typography
          component="span"
          sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
          variant="h4"
        >
          {loading ? 'loading' : Designer[0].name}{' '}
          {loading ? 'loading' : Designer[0].surname}
        </Typography>
        <Typography component="span" variant="body2">
          {loading ? 'loading' : Designer[0].profession}
        </Typography>
        <Typography component="span" variant="body2">
          {loading ? 'loading' : Designer[0].location}
        </Typography>
      </Container>
      <Container>
        <Box
          sx={{
            width: '100%',
            minHeight: { xs: '20rem', md: '50rem' },
            pb: '5rem',
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'white',
              color: 'white',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={{ color: 'white' }} label="Contest" {...a11yProps(0)} />
              <Tab sx={{ color: 'white' }} label="About" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Grid
              mt={4}
              container
              gap={2}
              item
              xs={12}
              justifyContent="space-between"
            >
              {loading ? (
                <LoadingComponent />
              ) : Designer[0].closed.length === 0 ? (
                <></>
              ) : (
                Designer[0].closed.map((contest) => (
                  <Grid key={contest._id} item xs={12} sm={5.7} md={3.7}>
                    <Link to={`/Single-Contest/${contest._id}`}>
                      <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="200"
                            image={
                              loading || !contest.images
                                ? 'htc'
                                : `${contest.images[0]}`
                            }
                            alt="green iguana"
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="span"
                            >
                              {contest.title}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Link>
                  </Grid>
                ))
              )}
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Container>
              <Grid container item xs={12}>
                <Grid container item xs={12} md={4} flexDirection="column">
                  <Typography
                    component="span"
                    sx={{ mb: '1rem' }}
                    variant="body1"
                  >
                    Member since: {loading ? 'loading' : Designer[0].createdAt}
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}
                    variant="h4"
                  >
                    Skills
                  </Typography>
                  <Grid container gap={1} mt={2} pr={1}>
                    {loading ? (
                      'loading'
                    ) : Designer[0].skills.length === 0 ? (
                      <></>
                    ) : (
                      Designer[0].skills.map((skill) => (
                        <Chip
                          key={skill._id}
                          sx={{ borderColor: 'green', color: 'white' }}
                          label={skill.name}
                          variant="outlined"
                        />
                      ))
                    )}
                  </Grid>
                  <Typography
                    component="span"
                    sx={{ mt: '1rem', fontSize: { xs: '1.5rem', md: '2rem' } }}
                    variant="h4"
                  >
                    Latest Contests
                  </Typography>
                  <Grid
                    gap="0.5rem"
                    pb="1rem"
                    pt="1rem"
                    container
                    flexDirection="column"
                  >
                    {loading ? (
                      'loading'
                    ) : Designer[0].closed.length === 0 ? (
                      <></>
                    ) : (
                      Designer[0].closed.map((contest) => (
                        <Link
                          key={contest._id}
                          style={{ textDecoration: 'none', color: 'white' }}
                          to={`/Single-Contest/${contest._id}`}
                        >
                          <Typography
                            component="span"
                            sx={{ mt: '0.5rem' }}
                            variant="body1"
                          >
                            {contest.title}
                          </Typography>
                        </Link>
                      ))
                    )}
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={8} flexDirection="column">
                  <Typography
                    component="span"
                    sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}
                    variant="h4"
                  >
                    Work Experiences
                  </Typography>
                  <Grid container gap="1rem" mt={2}>
                    {loading ? (
                      'loading'
                    ) : Designer[0].workexp.length === 0 ? (
                      <></>
                    ) : (
                      Designer[0].workexp.map((exp) => (
                        <Grid item xs={12} md={5.7} key={exp._id}>
                          <Paper
                            sx={{
                              padding: '1.5rem',
                              // backgroundColor: '#333',
                              // color: 'white',
                              borderRadius: '10px',
                              minHeight: '7rem !important',
                              height: '100%',
                              mt: '1rem',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '0.5rem',
                              maxWidth: '20rem',
                            }}
                            elevation={3}
                          >
                            <Typography component="span" variant="body1">
                              {exp.startdate} -{' '}
                              {!exp.enddate ? <>Current</> : exp.enddate}
                            </Typography>
                            <Typography component="span" variant="body1">
                              {exp.location}
                            </Typography>
                            <Typography component="span" variant="body1">
                              {exp.name}
                            </Typography>
                            <Typography component="span" variant="body1">
                              <a
                                target="_blank"
                                style={{ color: 'black' }}
                                href={exp.link}
                                rel="noreferrer"
                              >
                                {exp.link}
                              </a>
                            </Typography>
                          </Paper>
                        </Grid>
                      ))
                    )}
                  </Grid>
                  <Typography
                    component="span"
                    sx={{ mt: '3rem', fontSize: { xs: '1.5rem', md: '2rem' } }}
                    variant="h4"
                  >
                    Education
                  </Typography>
                  <Grid container gap="1rem" mt={2}>
                    {loading ? (
                      'loading'
                    ) : Designer[0].education.length === 0 ? (
                      <></>
                    ) : (
                      Designer[0].education.map((edu) => (
                        <Grid item xs={12} md={5.7} key={edu._id}>
                          <Paper
                            sx={{
                              padding: '1.5rem',
                              // backgroundColor: '#333',
                              // color: 'white',
                              borderRadius: '10px',
                              minHeight: '7rem !important',
                              height: '100%',
                              mt: '1rem',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '0.5rem',
                              maxWidth: '20rem',
                            }}
                            elevation={3}
                          >
                            <Typography component="span" variant="body1">
                              {edu.startdate} -{' '}
                              {!edu.enddate ? <>Current</> : edu.enddate}
                            </Typography>
                            <Typography component="span" variant="body1">
                              {edu.profession}
                            </Typography>
                            <Typography component="span" variant="body1">
                              {edu.uniname}
                            </Typography>
                            <Typography component="span" variant="body1">
                              {edu.degree}
                            </Typography>
                          </Paper>
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </CustomTabPanel>
        </Box>
      </Container>
    </Stack>
  );
};

export default SingleDesigner;
