import React, { useEffect, useState } from 'react';
import {
  Box,
  CssBaseline,
  Grid,
  Stack,
  Badge,
  Typography,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from 'axios';
import AdminPending from './AdminPending';
import Notifications from './Notifications';
import PaidHistory from './PaidHistory';
import { useAuthContext } from '../../../hooks/useAuthContext';
import AddBlog from './AddBlog';
import BlogPosts from './BlogPosts';
import EditBlog from './EditBlog';
import DownloadMails from './DownloadMails';

const AdminHome = () => {
  const [selected, setselected] = useState('Pending');
  const [adminData, setadminData] = useState([]);
  const [BlogToEdit, setBlogToEdit] = useState([]);
  const [loading, setloading] = useState([]);
  const { user } = useAuthContext();
  const fetchAdmin = async () => {
    try {
      setloading(true);
      const res = await axios.get(
        `https://backend.interior-crowd.com/api/v1/admin/${user.user}`,
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setadminData(res.data.admin);
      console.log(adminData);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAdmin();
  }, []);
  return (
    <Stack>
      <Box
        className="home-container"
        sx={{
          maxWidth: '1920px',
          width: '100vw',
          minHeight: '30rem',
          display: 'flex',
          // pt: '2rem',
          flexDirection: 'column',
          color: 'white',
        }}
      >
        <Grid
          container
          sx={{
            minHeight: '90vh',
            maxWidth: '100%',
            // border: '1px solid yellow',
          }}
        >
          <CssBaseline />
          <Grid
            container
            item
            sx={{
              // border: '1px solid red',
              flexShrink: 0,
              p: { xs: '4rem 1rem 0 1rem', md: '6rem 1rem' },
              flexDirection: { xs: 'row', md: 'column' },
              backgroundColor: '#333',
            }}
            xs={12}
            md={2}
          >
            <Grid
              item
              onClick={() => setselected('Notifications')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Notifications</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Pending')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Pending Payment</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('History')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>History</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Blogs')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Blog Posts</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Add Blog')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Add Blog Post</p>
            </Grid>
            <Grid
              item
              onClick={() => setselected('Download Emails')}
              sx={{
                cursor: 'pointer',
                padding: '1rem',
              }}
            >
              <p>Download Emails</p>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            container
            sx={{
              // flexGrow: 1,
              bgcolor: 'background.default',
              // border: '1px solid black',
              flexWrap: 'wrap',
              flexDirection: 'column',
              padding: '6rem 2rem 0 2rem',
            }}
          >
            <Typography textAlign="center" variant="h4" color={'black'}>
              {selected}
              {!loading && adminData[0].notifications.length === 0 ? (
                <></>
              ) : (
                <Badge
                  onClick={() => setselected('Notifications')}
                  badgeContent={
                    loading ? <></> : adminData[0].notifications.length
                  }
                  color="primary"
                  sx={{ cursor: 'pointer', ml: '2rem' }}
                >
                  <NotificationsIcon />
                </Badge>
              )}
            </Typography>
            <Box
              sx={{
                maxWidth: {
                  xs: '80vw',
                  sm: '100%',
                  padding: '0 1.5rem',
                  overflow: 'hidden',
                },
              }}
            >
              {selected === 'Pending' && <AdminPending />}
              {selected === 'Notifications' && <Notifications />}
              {selected === 'History' && <PaidHistory />}
              {selected === 'Download Emails' && <DownloadMails />}
              {selected === 'Blogs' && (
                <BlogPosts
                  setBlogToEdit={setBlogToEdit}
                  setselected={setselected}
                />
              )}
              {selected === 'Add Blog' && <AddBlog />}
              {selected === 'Edit Blog' && (
                <EditBlog
                  setBlogToEdit={setBlogToEdit}
                  BlogToEdit={BlogToEdit}
                  setselected={setselected}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default AdminHome;
