import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LoadingComponent from '../Ultils/LoadingComponent';
import { useAuthContext } from '../../hooks/useAuthContext';

const ClientPending = ({ ClientData, loading, fetchClient }) => {
  const { user } = useAuthContext();
  const handlePublish = async (contest) => {
    try {
      const checkout = await axios.post(
        `https://backend.interior-crowd.com/api/v1/payment/client-payment/${ClientData[0]._id}`,
        { purchaseContest: [contest] },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      window.location.replace(checkout.data.url);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    fetchClient();
  }, []);
  return (
    <Grid item xs={12} sx={{ maxHeight: '70vh', overflowY: 'scroll' }}>
      {/* <Typography variant='h5' component={'span'}>Total Contests: {loading ? <>loading</> : ClientData[0].contests.length}</Typography> */}
      <TableContainer component={Paper}>
        <Table sx={{}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Category</TableCell>
              <TableCell align="right">Budget</TableCell>
              <TableCell align="right">DeadLine</TableCell>
              <TableCell align="right">Publish</TableCell>
              <TableCell align="right">Details</TableCell>
              <TableCell align="right">Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <></>
            ) : ClientData[0].contests.length === 0 ? (
              <></>
            ) : (
              ClientData[0].contests.map((entry) => {
                if (entry.status === 'close' || entry.status === 'open') {
                  return false;
                }
                return (
                  <TableRow
                    key={entry._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="entry">
                      {entry.title}
                    </TableCell>
                    <TableCell align="right">{entry.status}</TableCell>
                    <TableCell align="right">{entry.category}</TableCell>
                    <TableCell align="right">{entry.budget}</TableCell>
                    <TableCell align="right">{entry.deadline}</TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => handlePublish(entry)}
                        variant="contained"
                      >
                        Publish
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/single-contest/${entry._id}`}>
                        <Button variant="contained">Details</Button>
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/Update-Contest/${entry._id}`}>
                        <Button variant="contained">Edit</Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LoadingComponent />}
    </Grid>
  );
};

export default ClientPending;
