import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Card,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import axios from 'axios';
import noimg from '../../assets/Home/noimg1.png';
import LoadingComponent from '../Ultils/LoadingComponent';
import ParticlesComponent from '../Ultils/ParticlesComponent';

const Contest = () => {
  const [loading, setloading] = useState(true);
  const [Contests, setContests] = useState([]);
  const fetchContests = async () => {
    try {
      const res = await axios.get('https://backend.interior-crowd.com/api/v1/contests');

      setContests(res.data.contests);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchContests();
  }, []);

  // if (loading) {
  //   return <div style={{marginTop: '5rem', minHeight: '50vh', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}><h1>loading</h1></div>
  // }

  return (
    <Stack>
      <Box
        className="home-container"
        sx={{
          maxWidth: '1920px',
          width: '100vw',
          height: '30rem',
          display: 'flex',
          mt: '2rem',
          flexDirection: 'column',
          color: 'white',
        }}
      >
        <Container>
          <Typography
            maxWidth="70%"
            mb={2}
            sx={{
              backgroundColor: '#29384D',
              padding: '1rem',
              borderRadius: '10px',
              mt: '8rem',
              fontSize: { xs: '1.25rem', md: '1.5rem' },
            }}
            variant="h4"
          >
            WONDERING HOW IT’S LIKE TO RENOVATE YOUR SPACE ON Interior Crowd?
          </Typography>
          <Typography
            sx={{
              maxWidth: { xs: '100%', md: '70%' },
              backgroundColor: '#29384D',
              padding: '1rem',
              borderRadius: '10px',
              fontSize: { xs: '1rem', md: '1.25rem' },
            }}
            variant="h5"
          >
            Get inspired by the dozens of contests and the hundreds of projects
            uploaded on Interior Crowd every day.
          </Typography>
        </Container>
        <Grid xs={12} sx={{ display: 'flex', height: '20rem', zIndex: '-2' }}>
          <ParticlesComponent />
        </Grid>
      </Box>
      <Box sx={{ minHeight: '50vh', backgroundColor: '#333' }}>
        <Container sx={{ mt: '2rem' }} maxWidth="lg">
          <Grid
            container
            alignItems="center"
            flexDirection="column"
            item
            xs={12}
            mt={4}
          >
            <Typography variant="h4" sx={{ m: '2rem 0' }} xs={12}>
              Contests
            </Typography>
            <Grid
              mt={4}
              container
              gap={2}
              item
              xs={12}
              justifyContent="space-between"
            >
              {loading ? (
                <LoadingComponent />
              ) : Contests.length === 0 ? (
                <>no Data</>
              ) : (
                Contests.map((contest) => {
                  if (contest.status === 'unpublished') return false;
                  return (
                    <Grid key={contest._id} item xs={12} sm={5.7} md={3.7}>
                      <Link to={`/Single-Contest/${contest._id}`}>
                        <Card sx={{ maxWidth: 345 }}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="200"
                              image={
                                loading || !contest.images[0]
                                  ? `${noimg}`
                                  : `${contest.images[0]}`
                              }
                              alt="green iguana"
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                {contest.title}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {contest.location}
                              </Typography>
                              <Grid container item xs={12} mt={1}>
                                <Grid item xs={6}>
                                  <Typography variant="body2">
                                    Type: {contest.category}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="body2">
                                    Sub Type: {contest.subcategory}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Link>
                    </Grid>
                  );
                })
              )}
            </Grid>
            <Link to="/Contests">
              <Button variant="contained" sx={{ m: '2.5rem 0 4rem 0' }}>
                Browse More
              </Button>
            </Link>
          </Grid>
        </Container>
      </Box>
    </Stack>
  );
};

export default Contest;
