import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import ImageViewer from 'react-simple-image-viewer';
import axios from 'axios';
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Card,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import parse from 'html-react-parser';
import noimg from '../../assets/Home/noimg1.png';

import LoadingComponent from '../Ultils/LoadingComponent';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SingleContest = () => {
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [loading, setloading] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imageData, setimageData] = useState([]);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [Contests, setContests] = useState([]);
  const fetchContests = async () => {
    try {
      const res = await axios.get(
        `https://backend.interior-crowd.com/api/v1/contests/${id}`,
      );
      setContests(res.data[0]);
      // console.log('yo', res.data[0].images);
      // eslint-disable-next-line
      res.data[0].images.map((entry) => {
        if (imageData.includes(entry)) {
          return false;
        }
        imageData.push(entry);
      });
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchContests();
  }, []);

  return (
    <Stack>
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100vw',
          minHeight: { xs: '20vh', md: '50rem' },
          display: 'flex',
          m: '8rem 0 4rem 0',
          flexDirection: 'column',
          color: 'white',
        }}
      >
        <Container>
          <Grid container item xs={12}>
            <Grid flexDirection="column" item container xs={12} md={4}>
              <Box className="S-C-img-container">
                {loading || !Contests.images ? (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      backgroundColor: '#333',
                      zIndex: '22',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <CircularProgress color="success" />
                  </Grid>
                ) : (
                  <img
                    className="S-C-img"
                    src={!Contests.images[0] ? noimg : Contests.images[0]}
                    alt="no img"
                  />
                )}
              </Box>
              <Grid container flexDirection="column" gap={2}>
                {loading || !Contests.assigned ? (
                  <Typography variant="h5">
                    Contest Status: {loading ? <>loading</> : Contests.status}
                  </Typography>
                ) : (
                  <>
                    <Typography variant="h5">
                      Winner | Earned: ${' '}
                      {loading ? <>loading</> : Contests.budget}
                    </Typography>
                    <Link to={`/Single-Designer/${Contests.assigned._id}`}>
                      <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              loading || !Contests.assigned.coverPic[0]
                                ? 'https://d1s5wdh0uf8y8f.cloudfront.net/1400x420/designers/5c2de4b6b7172d00257543cb/f9WiaASyaaFgnbK5ulkA_open space-0004.jpg'
                                : `${Contests.assigned.coverPic[0]}`
                            }
                            alt="green iguana"
                          />
                          <CardContent>
                            <Grid container item xs={12}>
                              <Grid item xs={8}>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                >
                                  {Contests.assigned.name}{' '}
                                  {Contests.assigned.surname}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {Contests.assigned.profession} |{' '}
                                  {Contests.assigned.location}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Box className="D-Profile-pic-container">
                                  <img
                                    className="D-Profile-pic"
                                    src={
                                      loading ||
                                      !Contests.assigned.profilePic[0]
                                        ? 'https://d1s5wdh0uf8y8f.cloudfront.net/200x200/designers/5c2de4b6b7172d00257543cb/JoIxS028Q9qgx6hxYAI6_Giacomo Cattani foto.jpg'
                                        : `${Contests.assigned.profilePic[0]}`
                                    }
                                    alt=""
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Link>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid
              pl={4}
              xs={12}
              item
              sx={{ mt: { xs: '1.5rem', md: 0 } }}
              md={8}
              flexDirection="column"
            >
              <Typography variant="h4">
                {loading ? 'loading' : Contests.title}
              </Typography>
              <Typography variant="body1">
                Category: {loading ? 'loading' : Contests.category} | Sub
                Category: {loading ? 'loading' : Contests.subcategory}
              </Typography>
              <Typography variant="body1">
                {loading ? 'loading' : Contests.location}
              </Typography>
              <Typography variant="body1">
                {loading ? 'loading' : `Budget: $${Contests.budget}`}
              </Typography>
              <Box sx={{ width: '100%', overflow: 'hidden' }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'white',
                    color: 'white',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      sx={{ color: 'white' }}
                      label="Images"
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{ color: 'white' }}
                      label="Recieved Projects"
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{ color: 'white' }}
                      label="Briefing"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Grid
                    mt={4}
                    container
                    gap={2}
                    item
                    xs={12}
                    justifyContent="space-between"
                  >
                    {loading || !Contests.images ? (
                      <LoadingComponent />
                    ) : Contests.images.length === 0 ? (
                      <></>
                    ) : (
                      Contests.images.map((image, index) => (
                        <Grid key={image._id} item xs={12} sm={5.7} md={3.8}>
                          {/* <Link to={`/Single-image/${image._id}`}> */}
                          <Card sx={{ maxWidth: 345 }}>
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                height="200"
                                image={image}
                                onClick={() => openImageViewer(index)}
                                // eslint-disable-next-line
                                key={index}
                                alt="green iguana"
                              />
                            </CardActionArea>
                          </Card>
                          {/* </Link> */}
                        </Grid>
                      ))
                    )}
                    <div
                      style={{
                        marginTop: '1rem',
                        zIndex: '9999999999999999999',
                      }}
                    >
                      {isViewerOpen && (
                        <ImageViewer
                          src={imageData}
                          currentIndex={currentImage}
                          disableScroll={false}
                          // eslint-disable-next-line
                          closeOnClickOutside={true}
                          onClose={closeImageViewer}
                        />
                      )}
                    </div>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Grid
                    mt={4}
                    container
                    gap={2}
                    item
                    xs={12}
                    justifyContent="space-between"
                  >
                    {loading || !Contests.projects ? (
                      <LoadingComponent />
                    ) : Contests.projects.length === 0 ? (
                      <></>
                    ) : (
                      Contests.projects.map((project) => (
                        <Grid key={project._id} item xs={12} sm={5.7} md={3.8}>
                          <Link to={`/Single-Project/${project._id}`}>
                            <Card sx={{ maxWidth: 345 }}>
                              <CardActionArea>
                                <CardMedia
                                  component="img"
                                  height="200"
                                  image={
                                    project.images[0]
                                      ? project.images[0]
                                      : noimg
                                  }
                                  alt="green iguana"
                                />
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="body1"
                                    component="span"
                                  >
                                    Title: {project.title}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Link>
                        </Grid>
                      ))
                    )}
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <Box
                    sx={{
                      wordWrap: 'break-word',
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Container>
                      {loading ? 'loading' : parse(Contests.description)}
                    </Container>
                  </Box>
                </CustomTabPanel>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Stack>
  );
};

export default SingleContest;
