import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Card,
  Chip,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'axios';
import noimg from '../../assets/Home/noimg1.png';

import LoadingComponent from '../Ultils/LoadingComponent';
import { useAuthContext } from '../../hooks/useAuthContext';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Client = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [loading, setloading] = useState(true);
  const [ClientData, setClientData] = useState([]);
  const fetchClient = async () => {
    try {
      const res = await axios.get(
        `https://backend.interior-crowd.com/api/v1/clients/${id}`,
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );

      setClientData(res.data.client);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchClient();
    }
  }, [user]);
  return (
    <Stack>
      <Box
        className="home-container"
        sx={{
          maxWidth: '1920px',
          width: '100vw',
          minHeight: '20vh',
          display: 'flex',
          mt: '5rem',
          flexDirection: 'column',
          color: 'white',
        }}
      >
        <Box className="single-D-img-container">
          <img
            src={
              loading || !ClientData[0].coverPic[0]
                ? 'https://d1s5wdh0uf8y8f.cloudfront.net/1400x420/designers/5c2de4b6b7172d00257543cb/f9WiaASyaaFgnbK5ulkA_open space-0004.jpg'
                : `${ClientData[0].coverPic[0]}`
            }
            alt=""
          />
        </Box>
      </Box>
      <div className="S-D-Profile-pic-container">
        <img
          className="S-D-Profile-pic"
          src={
            loading || !ClientData[0].profilePic[0]
              ? 'https://d1s5wdh0uf8y8f.cloudfront.net/200x200/designers/5c2de4b6b7172d00257543cb/JoIxS028Q9qgx6hxYAI6_Giacomo Cattani foto.jpg'
              : `${ClientData[0].profilePic[0]}`
          }
          alt=""
        />
      </div>
      <Container
        maxWidth="lg"
        sx={{
          mt: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.25rem',
        }}
      >
        <Typography
          sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
          component="span"
          variant="h4"
        >
          {loading ? 'loading' : ClientData[0].name}{' '}
          {loading ? 'loading' : ClientData[0].surname}
        </Typography>
        <Typography component="span" variant="body2">
          {loading ? 'loading' : ClientData[0].email}
        </Typography>
        <Typography component="span" variant="body2">
          {loading ? 'loading' : ClientData[0].phone}
        </Typography>
      </Container>
      <Container>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'white',
              color: 'white',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={{ color: 'white' }} label="Contest" {...a11yProps(0)} />
              <Tab sx={{ color: 'white' }} label="Closed" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Grid
              mt={4}
              container
              gap={2}
              item
              xs={12}
              justifyContent="space-between"
            >
              {loading ? (
                <LoadingComponent />
              ) : ClientData[0].contests.length === 0 ? (
                <>no contests</>
              ) : (
                ClientData[0].contests.map((contest) => {
                  if (contest.status === 'close') {
                    return false;
                  }
                  return (
                    <Grid key={contest._id} item xs={12} sm={5.7} md={3.7}>
                      <Link to={`/Single-Contest/${contest._id}`}>
                        <Card sx={{ maxWidth: 345 }}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="200"
                              // "https://d1s5wdh0uf8y8f.cloudfront.net/400x400/contests/615865fa085c0c0093ea78d7/resources/HyCuA0ncRSCmKZn43oGI_5863f255e7f5bfa05f7d036b.jpg"
                              image={
                                loading || !contest.images[0]
                                  ? noimg
                                  : `${contest.images[0]}`
                              }
                              alt="green iguana"
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="span"
                              >
                                {contest.title}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Link>
                    </Grid>
                  );
                })
              )}
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Grid
              mt={4}
              container
              gap={2}
              item
              xs={12}
              justifyContent="space-between"
            >
              {loading ? (
                <LoadingComponent />
              ) : ClientData[0].closed.length === 0 ? (
                <>no contests</>
              ) : (
                ClientData[0].closed.map((contest) => (
                  <Grid key={contest._id} item xs={12} sm={5.7} md={3.7}>
                    <Link to={`/Single-Contest/${contest._id}`}>
                      <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="200"
                            image={
                              loading || !contest.images
                                ? noimg
                                : `${contest.images[0]}`
                            }
                            alt="green iguana"
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="span"
                            >
                              {contest.title}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Link>
                  </Grid>
                ))
              )}
            </Grid>
          </CustomTabPanel>
        </Box>
      </Container>
    </Stack>
  );
};

export default Client;
