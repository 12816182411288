import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import LoadingComponent from '../Ultils/LoadingComponent';
import { useAuthContext } from '../../hooks/useAuthContext';

const Applied = ({ id }) => {
  const [projectsData, setprojectsData] = useState([]);
  const [loading, setloading] = useState(true);
  const { user } = useAuthContext();

  const fetchProjects = async () => {
    try {
      const res = await axios.get(
        `https://backend.interior-crowd.com/api/v1/projects/${id}`,
      );
      setprojectsData(res.data);
      console.log(res.data);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const withDrawProject = async (designerid, proID, conID) => {
    setloading(true);
    try {
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/designer/delete-project-from-designer/${designerid}`,
        { contestID: `${conID}` },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/contests/delete-project-from-contest/${conID}`,
        { projectID: `${proID}` },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      await axios.delete(`https://backend.interior-crowd.com/api/v1/projects/${proID}`, {
        headers: {
          'x-auth-token': user.token,
        },
      });
      fetchProjects();
      // console.log(designerid, proID);
      setloading(false);
      alert('projected deleted successfully');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <Grid item xs={12} sx={{ maxHeight: '70vh', overflowY: 'scroll' }}>
      {/* <Typography variant="h5" component="span">
        Total Projects: {loading ? <>loading</> : projectsData.length}
      </Typography> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="right">Category</TableCell>
              <TableCell align="right">Budget</TableCell>
              <TableCell align="right">DeadLine</TableCell>
              <TableCell align="right">Edit Proposal</TableCell>
              <TableCell align="right">Proposal</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <></>
            ) : projectsData.length === 0 ? (
              <></>
            ) : (
              projectsData.map((project) => {
                if (!project.contest) {
                  return <>no contest</>;
                }
                if (project.status === 'close') {
                  return false;
                }
                return (
                  <TableRow
                    key={project._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {project.contest.title}
                    </TableCell>
                    <TableCell align="right">
                      {project.contest.category}
                    </TableCell>
                    <TableCell align="right">
                      $ {project.contest.budget}
                    </TableCell>
                    <TableCell align="right">
                      {project.contest.deadline}
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/Update-Project/${project._id}`}>
                        <Button variant="contained">Edit Proposal</Button>
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/Single-Project/${project._id}`}>
                        <Button variant="contained">View Proposal</Button>
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        onClick={() =>
                          withDrawProject(
                            project.designer,
                            project._id,
                            project.contest._id,
                          )
                        }
                      >
                        Cancel
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LoadingComponent />}
    </Grid>
  );
};

export default Applied;
