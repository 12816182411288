import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Chip, Grid, Button, Paper } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import DesignerEditInfo from './Designer-Update/DesignerEditInfo';
import AddWork from './Designer-Update/AddWork';
import ShowExperiences from './Designer-Update/ShowExperiences';
import AddEdu from './Designer-Update/AddEdu';
import ShowEducation from './Designer-Update/ShowEducation';
import AddSkill from './Designer-Update/AddSkill';
import ShowSkill from './Designer-Update/ShowSkill';
import DesignerEditWork from './Designer-Update/DesignerEditWork';
import DesignerEditEdu from './Designer-Update/DesignerEditEdu';
import { useAuthContext } from '../../hooks/useAuthContext';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Settings = ({ DesignerData, loading, fetchDesigner, setloading }) => {
  const [value, setValue] = useState(0);
  const [editInfo, seteditInfo] = useState(false);
  const [toggleAddWork, settoggleAddWork] = useState(false);
  const [toggleAddEdu, settoggleAddEdu] = useState(false);
  const [toggleAddSkill, settoggleAddSkill] = useState(false);
  const [toggleEditWork, settoggleEditWork] = useState(false);
  const [toggleEditEdu, settoggleEditEdu] = useState(false);
  const [ShowWork, setShowWork] = useState(true);
  const [ShowEdu, setShowEdu] = useState(true);

  const [Images, setImages] = useState([]);
  const [coverPic, setcoverPic] = useState([]);
  const [alertfail, setalertfail] = useState(false);

  const [workToEdit, setworkToEdit] = useState(); // store the work exp value in it so that we can render them in the edit work form
  const [eduToEdit, seteduToEdit] = useState(); // store the edu value in it so that we can render them in the edit work form

  const [Deleteloading, setDeleteloading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // console.log(toggleAddWork);
  const { user } = useAuthContext();

  const handleUploadPic = async (e) => {
    try {
      setloading(true);
      setalertfail(true);
      setImages(e.target.files);
      const formData = new FormData();
      formData.append('designerID', DesignerData[0]._id);
      formData.append('profilePic', e.target.files[0]);
      const res = await axios.post(
        `https://backend.interior-crowd.com/api/v1/designer/update-designer-profilepic`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': user.token,
          },
        },
      );
      setloading(false);
      setalertfail(false);
      alert('uploaded');
    } catch (error) {
      setalertfail(true);
    }
  };

  const handleCoverPic = async (e) => {
    try {
      setloading(true);
      setalertfail(true);
      setImages(e.target.files);
      const formData = new FormData();
      formData.append('designerID', DesignerData[0]._id);
      formData.append('coverPic', e.target.files[0]);
      const res = await axios.post(
        `https://backend.interior-crowd.com/api/v1/designer/update-designer-coverpic`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': user.token,
          },
        },
      );
      setloading(false);
      setalertfail(false);
      alert('uploaded');
    } catch (error) {
      setalertfail(true);
    }
  };

  const handleEditInfo = () => {
    seteditInfo(true);
  };

  React.useEffect(() => {
    fetchDesigner();
  }, [
    editInfo,
    toggleAddWork,
    toggleAddEdu,
    toggleAddSkill,
    Deleteloading,
    toggleEditWork,
    toggleEditEdu,
  ]);

  return (
    <Box sx={{ width: '100%', minHeight: { xs: '20rem', md: '30rem' } }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Account Info" {...a11yProps(0)} />
          <Tab label="Qualifications" {...a11yProps(1)} />
          <Tab label="Education" {...a11yProps(2)} />
          <Tab label="Skills" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {editInfo ? (
          <>
            {loading ? (
              <>loading</>
            ) : (
              <DesignerEditInfo
                DesignerData={DesignerData}
                seteditInfo={seteditInfo}
              />
            )}
          </>
        ) : (
          <Grid container xs={12} flexDirection="column">
            <Grid container mt="1rem" justifyContent="space-between">
              <Grid item xs={12} sm={4}>
                <Typography color={'black'} sx={{ mb: '1rem' }} variant="body1">
                  Member Since:{' '}
                  {loading ? <>loading</> : DesignerData[0].createdAt}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                container
                justifyContent="flex-end"
                gap="1rem"
              >
                <Button onClick={handleEditInfo} variant="contained">
                  Edit
                </Button>

                {loading ? (
                  <Button disabled variant="contained">
                    <CircularProgress color="success" />
                  </Button>
                ) : (
                  <Button variant="contained" component="label">
                    Upload Profile Photo
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        handleUploadPic(e);
                      }}
                    />
                  </Button>
                )}
                {loading ? (
                  <Button disabled variant="contained">
                    <CircularProgress color="success" />
                  </Button>
                ) : (
                  <Button variant="contained" component="label">
                    Upload Cover Photo
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        handleCoverPic(e);
                      }}
                    />
                  </Button>
                )}
                {!loading && alertfail ? (
                  <Alert sx={{ mt: '1rem' }} severity="warning">
                    Something went wrong.
                  </Alert>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>

            <Typography color={'black'} variant="h4">
              {' '}
              {loading ? <>loading</> : DesignerData[0].name}{' '}
              {loading ? <>loading</> : DesignerData[0].surname}
            </Typography>
            <Typography color={'black'} variant="body1">
              Profession: {loading ? <>loading</> : DesignerData[0].profession}
            </Typography>
            <Typography color={'black'} variant="body1">
              Location: {loading ? <>loading</> : DesignerData[0].location}
            </Typography>
          </Grid>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid container flexDirection="column" alignItems="center">
          {toggleAddWork && (
            <AddWork
              settoggleAddWork={settoggleAddWork}
              setShowWork={setShowWork}
              id={DesignerData[0]._id}
            />
          )}
          {toggleEditWork && (
            <DesignerEditWork
              id={DesignerData[0]._id}
              seteditInfo={seteditInfo}
              settoggleEditWork={settoggleEditWork}
              setShowWork={setShowWork}
              setworkToEdit={setworkToEdit}
              workToEdit={workToEdit}
            />
          )}
          {ShowWork && (
            <ShowExperiences
              settoggleAddWork={settoggleAddWork}
              DesignerData={DesignerData}
              loading={loading}
              setDeleteloading={setDeleteloading}
              Deleteloading={Deleteloading}
              setShowWork={setShowWork}
              settoggleEditWork={settoggleEditWork}
              setworkToEdit={setworkToEdit}
            />
          )}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Grid container flexDirection="column" alignItems="center">
          {toggleAddEdu && (
            <AddEdu
              settoggleAddEdu={settoggleAddEdu}
              setShowEdu={setShowEdu}
              id={DesignerData[0]._id}
            />
          )}
          {toggleEditEdu && (
            <DesignerEditEdu
              id={DesignerData[0]._id}
              seteditInfo={seteditInfo}
              settoggleEditEdu={settoggleEditEdu}
              setShowEdu={setShowEdu}
              seteduToEdit={seteduToEdit}
              eduToEdit={eduToEdit}
            />
          )}
          {ShowEdu && (
            <ShowEducation
              settoggleAddEdu={settoggleAddEdu}
              DesignerData={DesignerData}
              loading={loading}
              setDeleteloading={setDeleteloading}
              Deleteloading={Deleteloading}
              setShowEdu={setShowEdu}
              settoggleEditEdu={settoggleEditEdu}
              seteduToEdit={seteduToEdit}
            />
          )}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Grid container flexDirection="column" alignItems="center">
          {toggleAddSkill ? (
            <AddSkill
              settoggleAddSkill={settoggleAddSkill}
              id={DesignerData[0]._id}
            />
          ) : (
            <ShowSkill
              settoggleAddSkill={settoggleAddSkill}
              DesignerData={DesignerData}
              loading={loading}
              setDeleteloading={setDeleteloading}
              Deleteloading={Deleteloading}
            />
          )}
        </Grid>
      </CustomTabPanel>
    </Box>
  );
};

export default Settings;
