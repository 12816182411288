import { Button, Chip, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useAuthContext } from '../../../hooks/useAuthContext';

const ShowSkill = ({
  settoggleAddSkill,
  loading,
  DesignerData,
  Deleteloading,
  setDeleteloading,
}) => {
  const { user } = useAuthContext();
  const handleDelete = async (id) => {
    try {
      setDeleteloading(true);
      await axios.patch(
        `https://backend.interior-crowd.com/api/v1/designer/delete-skill/${DesignerData[0]._id}`,
        { skillID: id },
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setDeleteloading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Button
        onClick={() => settoggleAddSkill(true)}
        sx={{
          width: '10rem',
        }}
        variant="contained"
      >
        Add Skill
      </Button>
      <Grid container gap={2} mt="1rem">
        {loading ? (
          <>loading</>
        ) : DesignerData[0].skills.length === 0 ? (
          <></>
        ) : (
          DesignerData[0].skills.map((entry) => (
            <Chip
              onDelete={() => handleDelete(entry._id)}
              key={entry._id}
              sx={{
                borderColor: 'green',
                minWidth: '7rem',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'green',
                color: 'white',
              }}
              label={entry.name}
              variant="filled"
            />
          ))
        )}
      </Grid>
    </>
  );
};

export default ShowSkill;
