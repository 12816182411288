import React from 'react';
import { Outlet } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import Navbar from '../Ultils/Navbar';
import Footer from '../Ultils/Footer';
import NewFooter from '../Home/NewFooter';

const HomeLayout = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '1920px ',
      // flexGrow: 1,
      // border: '10px solid green',
      overflowX: 'hidden',
    }}
  >
    <Navbar />
    <Outlet />
    <NewFooter />
  </div>
);

export default HomeLayout;
