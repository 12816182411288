import { Button, Grid, Paper, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { Editor } from 'primereact/editor';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../hooks/useAuthContext';

const EditBlog = ({ setselected, setBlogToEdit, BlogToEdit }) => {
  const [loading, setloading] = useState(false);
  const [alertfail, setalertfail] = useState(false);
  const navigate = useNavigate();
  const [text, setText] = useState({
    content: BlogToEdit.content,
    title: BlogToEdit.title,
    description: BlogToEdit.description,
  });
  const { user } = useAuthContext();
  const handleChange = (e) => {
    setText({
      ...text,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setalertfail(false);
    try {
      setloading(true);
      const res = await axios.post(
        `https://backend.interior-crowd.com/api/v1/blog/update-blog/${BlogToEdit._id}`,
        text,
        {
          headers: {
            'x-auth-token': user.token,
          },
        },
      );
      setText({});
      console.log(
        res,
        `https://backend.interior-crowd.com/api/v1/blog/update-blog/${BlogToEdit._id}`,
        text,
      );
      alert('posted successfully');
      navigate(`/Blog/${res.data.post.title}/${res.data.post._id}`);
      setloading(false);
      //   console.log(...formData);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Grid
      sx={{ mb: '5rem', mt: '2rem' }}
      container
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: '50rem',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <form
          action=""
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container gap={3} sx={{ padding: '0 1.5rem' }}>
            <Button variant="contained" onClick={() => setselected('Blogs')}>
              X
            </Button>
            <TextField
              required
              name="title"
              fullWidth
              id="standard-basic"
              label={`Title`}
              variant="standard"
              onChange={handleChange}
              value={text.title}
            />
            <TextField
              required
              name="description"
              fullWidth
              id="standard-basic"
              label={`Description`}
              variant="standard"
              onChange={handleChange}
              value={text.description}
            />
            <Editor
              value={text.content}
              onTextChange={(e) => {
                setText({ ...text, content: e.htmlValue });
              }}
              style={{ height: '320px', width: '100%' }}
            />
          </Grid>
          {loading ? (
            <Button
              type="submit"
              disabled
              variant="contained"
              sx={{
                m: '2rem 0 0 0',
              }}
            >
              {loading ? <CircularProgress color="success" /> : 'Edit Post'}
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              sx={{
                m: '2rem 0 0 0',
              }}
            >
              {loading ? <CircularProgress color="success" /> : 'Edit Post'}
            </Button>
          )}

          {!loading && alertfail ? (
            <Alert sx={{ mt: '1rem' }} severity="warning">
              Something went wrong.
            </Alert>
          ) : (
            <></>
          )}
        </form>
      </Paper>
    </Grid>
  );
};

export default EditBlog;
