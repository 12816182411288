import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import { useAuthContext } from '../../hooks/useAuthContext';
import logo from '../../assets/footer/logo_transparent.png';

const pages = [
  { title: 'Home', url: '/' },
  { title: 'Contests', url: '/Contests' },
  { title: 'Designers', url: '/Designers' },
  { title: 'About Us', url: '/About-Us' },
  { title: 'Blog', url: '/Blog' },
];

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [handleopenclose, sethandleopenclose] = useState(false);

  const { dispatch, user } = useAuthContext();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    sethandleopenclose(false);
  };

  const handleLogOut = () => {
    localStorage.removeItem('user');
    dispatch({ type: 'LOGOUT' });
    sethandleopenclose(false);
    navigate('/');
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: '9999',
        backgroundColor: '#333',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/">
            <Typography
              variant="h6"
              noWrap
              component="a"
              color="black"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              INTERIOR CROWD
            </Typography>
          </Link>

          {/* Logo Image only on mobile */}
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <img
              src={logo}
              alt="Interior Crowd Logo"
              style={{
                maxWidth: '100%',
                maxHeight: '100px',
                marginRight: '16px',
              }}
            />
          </Box>

          {/* Navigation Links for mobile */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              {/* <MenuIcon /> */}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <Link key={page.title} to={page.url}>
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography color="black" textAlign="center">
                      {page.title}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>

          {/* Navigation Links for desktop */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Link key={page.title} to={page.url}>
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.title}
                </Button>
              </Link>
            ))}
          </Box>

          {/* User Avatar or Login/Registration Buttons */}
          <Box sx={{ flexGrow: 0 }}>
            {user ? (
              <Grid container alignItems="center" gap="1rem">
                <IconButton
                  onClick={() => sethandleopenclose(!handleopenclose)}
                  sx={{ pr: '1rem' }}
                >
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>

                {handleopenclose ? (
                  <Menu
                    sx={{ mt: '70px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {user.role === 'client' ? (
                      <Link to={`/Client-Dashboard/${user.user}`}>
                        <MenuItem onClick={handleCloseUserMenu}>
                          <Typography color="black" textAlign="center">
                            Dashboard
                          </Typography>
                        </MenuItem>
                      </Link>
                    ) : (
                      <Link to={`/Designer-Dashboard/${user.user}`}>
                        <MenuItem onClick={handleCloseUserMenu}>
                          <Typography color="black" textAlign="center">
                            Dashboard
                          </Typography>
                        </MenuItem>
                      </Link>
                    )}
                    {user.role === 'client' ? (
                      <Link to={`/Single-Client/${user.user}`}>
                        <MenuItem onClick={handleCloseUserMenu}>
                          <Typography color="black" textAlign="center">
                            Profile
                          </Typography>
                        </MenuItem>
                      </Link>
                    ) : (
                      <Link to={`/Single-Designer/${user.user}`}>
                        <MenuItem onClick={handleCloseUserMenu}>
                          <Typography color="black" textAlign="center">
                            Profile
                          </Typography>
                        </MenuItem>
                      </Link>
                    )}

                    <MenuItem onClick={handleLogOut}>
                      <Typography color="black" textAlign="center">
                        Log Out
                      </Typography>
                    </MenuItem>
                  </Menu>
                ) : (
                  <></>
                )}
              </Grid>
            ) : (
              <div style={{ display: 'flex' }}>
                <Link to="/Role">
                  <Button sx={{ my: 2, color: 'white', display: 'block' }}>
                    Registration
                  </Button>
                </Link>
                <Link to="/Role-Login">
                  <Button sx={{ my: 2, color: 'white', display: 'block' }}>
                    Login
                  </Button>
                </Link>
              </div>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
