import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import AboutUs from './templates/About-Us/AboutUs';
import Contest from './templates/Contests/Contest';
import Designer from './templates/Designer/Designer';
import Home from './templates/Home/Home';
import HomeLayout from './templates/Layout/HomeLayout';
import AdminLayout from './templates/Layout/AdminLayout';
import NoPage from './templates/Ultils/NoPage';
import Login from './templates/Authentication/Login';
import Registration from './templates/Authentication/Registration';
import Role from './templates/Authentication/Role';
import DesignerRegisteration from './templates/Authentication/DesignerRegisteration';
import ClientRegistration from './templates/Authentication/ClientRegistration';
import DesignerDashboard from './templates/Dashboard/DesignerDashboard';
import ClientDashboard from './templates/Dashboard/ClientDashboard';
import AddProject from './templates/Dashboard/AddProject';
import SingleContest from './templates/Contests/SingleContest';
import SingleDesigner from './templates/Designer/SingleDesigner';
import AllProjects from './templates/Projects/AllProjects';
import SingleProject from './templates/Projects/SingleProject';
import ClientSingleProject from './templates/Projects/ClientSingleProject';
import RoleLogin from './templates/Authentication/RoleLogin';
import DesignerLogin from './templates/Authentication/DesignerLogin';
import Client from './templates/Client/Client';
import ContestUpdate from './templates/Contests/ContestUpdate';
import ProjectUpdate from './templates/Projects/ProjectUpdate';
import ProtectedRoute from './hooks/ProtectedRoute';
import PaymentSuccess from './templates/payment/PaymentSuccess';
import AdminLogin from './templates/Admin-Panel/Auth/Login';
import AdminHome from './templates/Admin-Panel/Dashboard/AdminHome';
import AdminProtected from './hooks/AdminProtected';
import BlogHome from './templates/Blog/BlogHome';
import SingleBlogPost from './templates/Blog/SingleBlogPost';
import NewHome from './templates/Home/NewHome';
import NewFooter from './templates/Home/NewFooter';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="Client-Dashboard/:id" element={<ClientDashboard />} />
            <Route
              path="Client-Single-Project/:id"
              element={<ClientSingleProject />}
            />
            <Route path="All-Projects/:id" element={<AllProjects />} />
            <Route path="Update-Project/:id" element={<ProjectUpdate />} />
            <Route
              path="Designer-Dashboard/:id"
              element={<DesignerDashboard />}
            />
            <Route
              path="Add-Project/:designerid/:title/:clientid/:contestid"
              element={<AddProject />}
            />
          </Route>

          <Route path="Payment-Success" element={<PaymentSuccess />} />

          {/* Open Routes */}

          <Route path="home2" element={<Home />} />
          <Route index element={<NewHome />} />
          <Route path="About-Us" element={<AboutUs />} />
          <Route path="Designers" element={<Designer />} />
          <Route path="Contests" element={<Contest />} />
          <Route path="Single-Contest/:id" element={<SingleContest />} />
          <Route path="Update-Contest/:id" element={<ContestUpdate />} />
          <Route path="Single-Designer/:id" element={<SingleDesigner />} />
          <Route path="Single-Client/:id" element={<Client />} />
          <Route path="Single-Project/:id" element={<SingleProject />} />
          <Route path="Blog" element={<BlogHome />} />
          <Route path="Blog/:title/:id" element={<SingleBlogPost />} />

          {/* Open Authentication Routes */}
          <Route path="Login" element={<Login />} />
          <Route path="Designer-Login" element={<DesignerLogin />} />
          <Route path="Role-Login" element={<RoleLogin />} />
          <Route path="Register" element={<Registration />} />
          <Route
            path="Designer-Registration"
            element={<DesignerRegisteration />}
          />
          <Route path="Client-Registration" element={<ClientRegistration />} />
          <Route path="role" element={<Role />} />
          <Route path="*" element={<NoPage />} />
        </Route>

        <Route path="/Admin" element={<AdminLayout />}>
          <Route element={<AdminProtected />}>
            <Route index element={<AdminHome />} />
          </Route>
          <Route path="Admin-Login" element={<AdminLogin />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
