import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Container,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Editor } from 'primereact/editor';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { Box, styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';

const ProjectUpdate = () => {
  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
        box-sizing: border-box;
        maxWidth: '35rem';
        width: 100%;
        min-height: 10rem;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 12px;
        border-radius: 12px 12px 0 12px;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${
          theme.palette.mode === 'dark' ? grey[700] : grey[200]
        };
        box-shadow: 0px 2px 2px ${
          theme.palette.mode === 'dark' ? grey[900] : grey[50]
        };
    
        &:hover {
          border-color: ${blue[400]};
        }
    
        &:focus {
          outline: 0;
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${
            theme.palette.mode === 'dark' ? blue[600] : blue[200]
          };
        }
    
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `,
  );
  const { id } = useParams();
  const [Images, setImages] = useState([]);
  const [FilesData, setFilesData] = useState([]);
  const [loading, setloading] = useState(true);
  const [alertfail, setalertfail] = useState(false);
  const [Project, setProject] = useState([]);
  const [formInfo, setformInfo] = useState({
    description: '',
  });
  const [text, setText] = useState({
    description: '',
  });
  const { user } = useAuthContext();
  const fetchProject = async () => {
    try {
      const res = await axios.get(
        `https://backend.interior-crowd.com/api/v1/projects/get-project/${id}`,
      );
      setProject(res.data[0]);
      //   console.log(res);
      setformInfo({
        description: `description: ${res.data[0].description}`,
      });
      setText({
        description: res.data[0].description,
      });
      //   console.log('hah', formInfo);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setformInfo({
      ...formInfo,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setalertfail(false);
    setloading(true);

    const formData = new FormData(e.currentTarget);
    formData.append('description', text.description);
    formData.append('projectID', Project._id);
    for (let i = 0; i < Images.length; i++) {
      formData.append('images', Images[i]);
    }
    for (let i = 0; i < FilesData.length; i++) {
      formData.append('files', FilesData[i]);
    }

    if (!formData.get('title')) {
      formData.set('title', Project.title);
    }
    if (!formData.get('description')) {
      formData.set('description', Project.description);
    }

    try {
      await axios.post(
        `https://backend.interior-crowd.com/api/v1/project/update-Project`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': user.token,
          },
        },
      );
      setText({});
      setloading(false);
      alert('updated');
      navigate(`/Single-Project/${Project._id}`);
    } catch (error) {
      setloading(false);
      setalertfail(true);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          minHeight: '100vh',
        }}
      >
        <CircularProgress color="success" />
      </Grid>
    );
  }

  return (
    <Stack
      sx={{
        m: { xs: '3rem 0', sm: '5rem 0', md: '5rem 0' },
      }}
      direction="column"
      justifyContent="center"
      spacing={2}
    >
      <Typography textAlign="center" component="span" variant="h5">
        Update Project
      </Typography>
      <Grid container width="100%" justifyContent="center" alignItems="center">
        <Paper
          elevation={3}
          sx={{
            maxWidth: '35rem',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            alignItems: 'center',
          }}
        >
          <form
            action=""
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              gap={3}
              sx={{ padding: '0 1.5rem', maxWidth: '35rem' }}
            >
              <TextField
                name="title"
                fullWidth
                id="standard-basic"
                label={`title: ${Project.title}`}
                variant="standard"
              />
              <Grid container alignItems="center" gap="1rem" item xs={12}>
                {Images.length === 0 ? (
                  <Button variant="contained" component="label">
                    Upload Images *
                    <input
                      type="file"
                      hidden
                      multiple
                      onChange={(e) => {
                        setImages(e.target.files);
                      }}
                    />
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={() => setImages([])}
                      variant="contained"
                      component="label"
                    >
                      Discard Selected Images
                    </Button>
                    <Button
                      sx={{ ml: '1rem' }}
                      variant="contained"
                      component="label"
                    >
                      +
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          setImages((prev) => [...prev, e.target.files[0]]);
                        }}
                      />
                    </Button>
                    <p style={{ marginTop: '1rem' }}>
                      {Images.length} Images Selected.
                    </p>
                  </>
                )}
              </Grid>
              <Grid container alignItems="center" gap="1rem" item xs={12}>
                {FilesData.length === 0 ? (
                  <Button variant="contained" component="label">
                    Upload Files *
                    <input
                      type="file"
                      hidden
                      multiple
                      onChange={(e) => {
                        setFilesData(e.target.files);
                      }}
                    />
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={() => setFilesData([])}
                      variant="contained"
                      component="label"
                    >
                      Discard Selected Files
                    </Button>
                    <Button
                      sx={{ ml: '1rem' }}
                      variant="contained"
                      component="label"
                    >
                      +
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          setFilesData((prev) => [...prev, e.target.files[0]]);
                        }}
                      />
                    </Button>
                    <p style={{ marginTop: '1rem' }}>
                      {FilesData.length} Files Selected.
                    </p>
                  </>
                )}
              </Grid>
              <Grid container xs={12}>
                {/* <Textarea
                  name="description"
                  aria-label="empty textarea"
                  placeholder={`Description: ${Project.description}`}
                /> */}
                <Container>
                  <Editor
                    value={text.description}
                    required
                    onTextChange={(e) => {
                      setText({ ...text, description: e.htmlValue });
                    }}
                    style={{ height: '320px', width: '100%' }}
                  />
                </Container>
              </Grid>
            </Grid>
            {loading ? (
              <Button
                type="submit"
                disabled
                variant="contained"
                sx={{
                  m: '2rem 0 0 0',
                }}
              >
                {loading ? (
                  <CircularProgress color="success" />
                ) : (
                  'Update Project'
                )}
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{
                  m: '2rem 0 0 0',
                }}
              >
                {loading ? (
                  <CircularProgress color="success" />
                ) : (
                  'Update Project'
                )}
              </Button>
            )}
            {!loading && alertfail ? (
              <Alert sx={{ mt: '1rem' }} severity="warning">
                Something went wrong.
              </Alert>
            ) : (
              <></>
            )}
          </form>
        </Paper>
      </Grid>
    </Stack>
  );
};

export default ProjectUpdate;
